import React, { useContext, useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { ModelContext } from "../context/model.context";
import { AppContext, companies } from "../context/app.context";
import Dropdown from "./Dropdown";
import contrastDark from "./../assets/icons/contrastDark.svg";
import contrastLight from "./../assets/icons/contrastLight.svg";
import accountCircle from "./../assets/icons/accountCircle.svg";
import check from "./../assets/icons/check.svg";
import logoBigDark from "./../assets/Logo-KI-big-dark.svg";
import logoBig from "./../assets/Logo-KI-big.png";

const Header = () => {
  const { mongoUser } = useContext(ModelContext);
  const {
    appEnvirons,
    companyCode,
    whichList,
    darkMode,
    screenAccess,
    setCompanyCode,
    setWhichList,
    setDarkMode,
    setOpenJobSettingsModal,
  } = useContext(AppContext);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const { instance } = useMsal();

  const userAcronym =
    mongoUser?.profile?.name
      .split(" ")
      .map((word) => word[0])
      .join("") || "";

  // - - - - - - - - - -

  const selectCompanyHandler = (code) => {
    setCompanyCode(code);
  };

  // - - - - - - - - - -

  const onLogout = () => {
    instance
      .logoutPopup({
        account: instance.getActiveAccount(),
      })
      .catch((error) => {
        console.log(
          "Header onLogout handleLoginError (alert to user 'Logout failed'):",
          error
        );
        alert("Logout failed!");
      });
  };

  // - - - - - - - - - - - - - - - - -  EFFECT HOOKS  - - - - - - - - - - - - - - - - - -
  //
  // companyCode
  // darkMode
  //

  useEffect(() => {
    const initialCompany = companies.find(
      (company) => company.code === companyCode
    );
    setSelectedCompany(initialCompany);
  }, [companyCode]);

  // - - - - - - - - - -

  useEffect(() => {
    localStorage.setItem("darkMode", JSON.stringify(darkMode));

    if (darkMode) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [darkMode]);

  // - - - - - - - - - -

  return (
    <header className="h-16 min-h-16 px-4 flex items-center bg-skin-primary border-b-2 border-yellow">
      {mongoUser ? (
        <>
          <div className="w-56">
            {selectedCompany ? (
              <Dropdown
                trigger={
                  <div className="w-56 flex items-center">
                    <img
                      src={selectedCompany.logo}
                      alt="logo"
                      className="w-10"
                    />
                    <span className="flex-1 text-xl font-normal ml-2 whitespace-nowrap">
                      {selectedCompany.name}
                    </span>
                    <span className="ml-2 arrow arrow-down"></span>
                  </div>
                }
                menuClasses="left-[-1rem] translate-y-3 w-64"
                menu={
                  <ul className="text-sm font-normal whitespace-nowrap">
                    {companies.map(({ name, logo, code }) => (
                      <li
                        onClick={() => selectCompanyHandler(code)}
                        key={code}
                        className="px-4 py-2 flex items-center cursor-default hover:bg-skin-base-hover"
                      >
                        <img src={logo} alt="logo" className="w-10" />
                        <span className="flex-1 text-xl ml-1 overflow-hidden text-ellipsis">
                          {name}
                        </span>
                        <img
                          src={check}
                          alt="selected"
                          className={companyCode === code ? "" : "hidden"}
                        />
                      </li>
                    ))}
                  </ul>
                }
              ></Dropdown>
            ) : (
              ""
            )}
          </div>
          <div className="flex-1 ml-4 text-2xl font-light">
            |<span className="font-bold ml-4">Jobs Scheduler</span>
          </div>
        </>
      ) : (
        <div className="h-10 w-[45%]">
          {darkMode ? (
            <img className="h-full" src={logoBig} alt="Keller" />
          ) : (
            <img className="h-full" src={logoBigDark} alt="Keller" />
          )}
        </div>
      )}

      <div className="flex-1 ml-4 text-2xl font-light">
        <span className="font-bold ml-4">
          {appEnvirons.appEnv !== "production"
            ? appEnvirons.appEnv + " server"
            : ""}
        </span>
      </div>
      {mongoUser ? (
        <>
          {screenAccess?.includes("optimizer") ? (
            <button
              className={`${
                whichList === "optimizer"
                  ? "text-skin-primary bg-skin-base"
                  : "text-skin-link"
              } text-lg font-bold px-4 h-full`}
              onClick={() => setWhichList("optimizer")}
            >
              Optimizer
            </button>
          ) : (
            <></>
          )}
          {screenAccess?.includes("scheduler") ? (
            <button
              className={`${
                whichList === "schedule"
                  ? "text-skin-primary bg-skin-base"
                  : "text-skin-link"
              } text-lg font-bold px-4 h-full`}
              onClick={() => setWhichList("schedule")}
            >
              Scheduler
            </button>
          ) : (
            <></>
          )}
          {screenAccess?.includes("jobList") ? (
            <button
              className={`${
                whichList === "list"
                  ? "text-skin-primary bg-skin-base"
                  : "text-skin-link"
              } text-lg font-bold px-4 h-full`}
              onClick={() => setWhichList("list")}
            >
              Jobs
            </button>
          ) : (
            <></>
          )}
        </>
      ) : (
        ""
      )}
      <span className="w-10"></span>
      <button
        onClick={() => setDarkMode(!darkMode)}
        type="button"
        className="w-6 h-6 ml-4"
      >
        {darkMode ? (
          <img src={contrastDark} alt="dark" />
        ) : (
          <img src={contrastLight} alt="light" />
        )}
      </button>
      {mongoUser ? (
        <div className="ml-4">
          <Dropdown
            trigger={
              <div className="w-10 h-10 leading-10 rounded-full bg-skin-button-accent text-black font-bold text-center">
                {userAcronym}
              </div>
            }
            menuClasses="right-0 translate-y-3"
            menu={
              <ul className="text-sm font-normal whitespace-nowrap">
                <li
                  onClick={(event) => event.stopPropagation()}
                  className="flex px-4 py-2"
                  title={mongoUser.profile?.name}
                >
                  <img src={accountCircle} alt="Account" />
                  <span className="text-base ml-1 overflow-hidden text-ellipsis">
                    {mongoUser.profile?.name}
                  </span>
                </li>
                <li className="px-4 py-2 cursor-default hover:bg-skin-base-hover">
                  Profile Settings
                </li>
                <li
                  className="px-4 py-2 cursor-default hover:bg-skin-base-hover"
                  onClick={() =>
                    setOpenJobSettingsModal({
                      show: true,
                    })
                  }
                >
                  Job Settings
                </li>
                <li
                  onClick={onLogout}
                  className="px-4 py-2 cursor-default hover:bg-skin-base-hover"
                >
                  Log Out
                </li>
              </ul>
            }
          ></Dropdown>
        </div>
      ) : (
        ""
      )}
    </header>
  );
};

export default Header;
