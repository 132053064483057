const Checkbox = ({ label, value, onChange, disabled }) => {
  const id = `checkbox-${label}-[${Math.floor(Math.random() * 1000)}]`;
  return (
    <div
      className={`${
        disabled ? "cursor-default" : "cursor-pointer"
      } flex items-center`}
    >
      <input
        type="checkbox"
        id={id}
        checked={value}
        onChange={onChange}
        className="hidden"
        disabled={disabled}
      />

      <label
        htmlFor={id}
        className={`${
          disabled ? "cursor-default" : "cursor-pointer"
        } text-sm font-semibold flex items-center`}
      >
        <span className={`${disabled ? "opacity-70" : ""} `}>
          {value ? (
            <svg
              htmlFor={id}
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              className="fill-skin-base mr-2"
            >
              <g clipPath="url(#clip0_6_1094)">
                <path d="M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM19 19H5V5H19V19ZM17.99 9L16.58 7.58L9.99 14.17L7.41 11.6L5.99 13.01L9.99 17L17.99 9Z" />
              </g>
              <defs>
                <clipPath id="clip0_6_1094">
                  <rect width="24" height="24" />
                </clipPath>
              </defs>
            </svg>
          ) : (
            <svg
              htmlFor={id}
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              className="fill-skin-base mr-2"
            >
              <mask
                id="mask0_6_2415"
                style={{ maskType: "alpha" }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
              >
                <rect width="24" height="24" />
              </mask>
              <g mask="url(#mask0_6_2415)">
                <path d="M5 21C4.45 21 3.97917 20.8042 3.5875 20.4125C3.19583 20.0208 3 19.55 3 19V5C3 4.45 3.19583 3.97917 3.5875 3.5875C3.97917 3.19583 4.45 3 5 3H19C19.55 3 20.0208 3.19583 20.4125 3.5875C20.8042 3.97917 21 4.45 21 5V19C21 19.55 20.8042 20.0208 20.4125 20.4125C20.0208 20.8042 19.55 21 19 21H5ZM5 19H19V5H5V19Z" />
              </g>
            </svg>
          )}
        </span>
        {label}
      </label>
    </div>
  );
};

export default Checkbox;
