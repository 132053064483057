import React, { useContext, useEffect, useState } from "react";
import Modal from "../../components/Modal";
import Button from "../../components/Button";
import WeekPicker from "../../components/WeekPicker";
import RadioButton from "../../components/RadioButton";
import Input from "../../components/Input";
import { AppContext } from "../../context/app.context";
import { ScheduleContext } from "../../context/schedule.context";
import { ModelContext } from "../../context/model.context";
import {
  COLUMN_DAY_ARRAY,
  FILTERS_SURFACE_ARRAY,
  MILLISECONDS_PER_DAY,
} from "../../utility/keys";

// enums for tab
const Tabs = Object.freeze({
  AVAILABILITY: "Availability",
  DEFAULTS: "Availability Defaults",
  CAPACITIES: "Capacities",
});

// enums for tab
const Statuses = Object.freeze({
  AVAILABILITY: "Available",
  OFF: "OFF",
  BUFFER: "BUFFER",
  CUSTOM: "Custom",
});

const CAPACITIES_HEADER = [
  "",
  "Sequence",
  "Once / Day",
  "Furn / Day",
  "Step / Day",
  "Twice / Day",
  "Furn / Day",
  "Furn / Day",
];

function CrewDetailModal({ crewDetail, setOpenCrewDetailModal }) {
  const {
    weekDatesArray,
    createWeekDatesArray,
    mondayThisWeek,
    dataFormatting_payrollFields,
  } = useContext(AppContext);
  const { scheduleArray } = useContext(ScheduleContext);
  const { updateAttributeList, createCrewDefaultsAttribute, attributesList } =
    useContext(ModelContext);

  const [activeTab, setActiveTab] = useState(Tabs.AVAILABILITY);
  const [dayAttributes, setDayAttributes] = useState({});
  const [customValues, setCustomValues] = useState(Array(7).fill(""));
  const [dayAttributesDefault, setDayAttributesDefault] = useState({});
  const [customValuesDefault, setCustomValuesDefault] = useState(
    Array(7).fill("")
  );

  const capacityValues = [
    [
      "carpet",
      crewDetail?.capacity_carpet_prime_secondary,
      crewDetail?.capacity_carpet_once,
      crewDetail?.capacity_carpet_once_furniture,
      crewDetail?.capacity_carpet_once_steps,
      crewDetail?.capacity_carpet_twice,
      crewDetail?.capacity_carpet_twice_furniture,
      crewDetail?.capacity_carpet_twice_steps,
    ],
    [
      "vinylPlank",
      crewDetail?.capacity_vinyl_prime_secondary,
      crewDetail?.capacity_vinyl_once,
      crewDetail?.capacity_vinyl_once_furniture,
      crewDetail?.capacity_vinyl_once_steps,
      crewDetail?.capacity_vinyl_twice,
      crewDetail?.capacity_vinyl_twice_furniture,
      crewDetail?.capacity_vinyl_twice_steps,
    ],
    [
      "vinylSheet",
      crewDetail?.capacity_vinylSheet_prime_secondary,
      crewDetail?.capacity_vinylSheet_once,
      crewDetail?.capacity_vinylSheet_once_furniture,
      crewDetail?.capacity_vinylSheet_once_steps,
      crewDetail?.capacity_vinylSheet_twice,
      crewDetail?.capacity_vinylSheet_twice_furniture,
      crewDetail?.capacity_vinylSheet_twice_steps,
    ],
    [
      "tile",
      crewDetail?.capacity_tile_prime_secondary,
      crewDetail?.capacity_tile_once,
      crewDetail?.capacity_tile_once_furniture,
      crewDetail?.capacity_tile_once_steps,
      crewDetail?.capacity_tile_twice,
      crewDetail?.capacity_tile_twice_furniture,
      crewDetail?.capacity_tile_twice_steps,
    ],
    [
      "backsplash",
      crewDetail?.capacity_backsplash_prime_secondary,
      crewDetail?.capacity_backsplash_once,
      crewDetail?.capacity_backsplash_once_furniture,
      crewDetail?.capacity_backsplash_once_steps,
      crewDetail?.capacity_backsplash_twice,
      crewDetail?.capacity_backsplash_twice_furniture,
      crewDetail?.capacity_backsplash_twice_steps,
    ],
    [
      "floating",
      crewDetail?.capacity_floating_prime_secondary,
      crewDetail?.capacity_floating_once,
      crewDetail?.capacity_floating_once_furniture,
      crewDetail?.capacity_floating_once_steps,
      crewDetail?.capacity_floating_twice,
      crewDetail?.capacity_floating_twice_furniture,
      crewDetail?.capacity_floating_twice_steps,
    ],
    [
      "glueDown",
      crewDetail?.capacity_glueDown_prime_secondary,
      crewDetail?.capacity_glueDown_once,
      crewDetail?.capacity_glueDown_once_furniture,
      crewDetail?.capacity_glueDown_once_steps,
      crewDetail?.capacity_glueDown_twice,
      crewDetail?.capacity_glueDown_twice_furniture,
      crewDetail?.capacity_glueDown_twice_steps,
    ],
    [
      "nailDown",
      crewDetail?.capacity_nailDown_prime_secondary,
      crewDetail?.capacity_nailDown_once,
      crewDetail?.capacity_nailDown_once_furniture,
      crewDetail?.capacity_nailDown_once_steps,
      crewDetail?.capacity_nailDown_twice,
      crewDetail?.capacity_nailDown_twice_furniture,
      crewDetail?.capacity_nailDown_twice_steps,
    ],
  ];

  // -------
  const getSelectedStatus = (dayIndex) => {
    if (activeTab === Tabs.AVAILABILITY) {
      return (
        dayAttributes[weekDatesArray[dayIndex]] || {
          status: Statuses.AVAILABILITY,
        }
      );
    } else {
      return (
        dayAttributesDefault[dayIndex] || {
          status: Statuses.AVAILABILITY,
        }
      );
    }
  };

  // ------

  const getCustomValues = (dayIndex) => {
    if (activeTab === Tabs.AVAILABILITY) {
      return customValues[dayIndex];
    } else {
      return customValuesDefault[dayIndex];
    }
  };

  // -------

  const onChangeStatus = (status, dayIndex, customText = "") => {
    const installer = dataFormatting_payrollFields(crewDetail?.payrollNo);
    const date = new Date(weekDatesArray[dayIndex]).toLocaleDateString("en-us");
    console.log(
      "CrewDetailModal onChangeStatus, date: ",
      date,
      ", installer: ",
      installer,
      ", status: ",
      status
    );
    if (activeTab === Tabs.AVAILABILITY) {
      // availability - save changed to Attributes list and push to Mongo
      updateAttributeList(date, installer, status, customText);
    } else {
      // all other changes, which appears to only be DEFAULTS
      if (status !== Statuses.CUSTOM && customValuesDefault[dayIndex]) {
        customValuesDefault[dayIndex] = "";
        setCustomValuesDefault([...customValuesDefault]);
      }

      // local changes
      const newArray = {
        ...dayAttributesDefault,
        [dayIndex]: { status, custom: customText },
      };
      setDayAttributesDefault(newArray);
      console.log(
        "CrewDetailModal onChangeStatus, setDayAttributesDefault: ",
        newArray,
        ", setCustomValuesDefault: ",
        customValuesDefault
      );

      // state & Collection changes
      createCrewDefaultsAttribute(installer, newArray, customValuesDefault);
    }
  };

  // -----------

  const saveCustomStatus = (dayIndex) => {
    if (activeTab === Tabs.AVAILABILITY) {
      if (
        customValues[dayIndex] !==
        (dayAttributes[weekDatesArray[dayIndex]]?.custom || "")
      ) {
        if (customValues[dayIndex]) {
          onChangeStatus(
            Statuses.CUSTOM,
            dayIndex,
            customValues[dayIndex] || ""
          );
        } else {
          onChangeStatus(Statuses.AVAILABILITY, dayIndex);
        }
      }
    } else {
      if (
        customValuesDefault[dayIndex] !==
        (dayAttributesDefault[dayIndex]?.custom || "")
      ) {
        if (customValuesDefault[dayIndex]) {
          onChangeStatus(
            Statuses.CUSTOM,
            dayIndex,
            customValues[dayIndex] || ""
          );
        } else {
          onChangeStatus(Statuses.AVAILABILITY, dayIndex);
        }
      }
    }
  };

  // -----------

  const onCustomChange = (value, dayIndex) => {
    if (activeTab === Tabs.AVAILABILITY) {
      customValues[dayIndex] = value;
      setCustomValues([...customValues]);
    } else {
      customValuesDefault[dayIndex] = value;
      setCustomValuesDefault([...customValuesDefault]);
    }
  };

  // - - - - - - - - - -

  const onWeekToday = () => {
    // user is changing weeks - update the View
    console.log("App onWeekToday...");
    const newDate = new Date(mondayThisWeek);
    createWeekDatesArray(newDate);
  };

  // - - - - - - - - - -

  const onWeekBehind = () => {
    // user is changing weeks - update the View
    console.log("App onWeekBehind...");
    const newDate = new Date(
      Date.parse(weekDatesArray[0]) - MILLISECONDS_PER_DAY * 7
    );
    createWeekDatesArray(newDate);
  };

  // - - - - - - - - - -

  const onWeekAhead = () => {
    // user is changing weeks - update the View
    console.log("App onWeekAhead...");
    const newDate = new Date(
      Date.parse(weekDatesArray[0]) + MILLISECONDS_PER_DAY * 7
    );
    createWeekDatesArray(newDate);
  };

  // - - - - - - - - - - - - - - - - -  EFFECT HOOKS  - - - - - - - - - - - - - - - - - -
  // - - - - - - - - - -

  useEffect(() => {
    const newDayAttributes = scheduleArray.reduce((prev, curr) => {
      if (
        dataFormatting_payrollFields(crewDetail?.payrollNo) ===
          dataFormatting_payrollFields(curr.installer) &&
        curr.attribute
      ) {
        switch (curr?.encodedSmallTitle) {
          case Statuses.BUFFER:
            prev[curr.start] = {
              status: Statuses.BUFFER,
            };
            break;
          case Statuses.OFF:
            prev[curr.start] = {
              status: Statuses.OFF,
            };
            break;

          default:
            prev[curr.start] = {
              status: Statuses.CUSTOM,
              custom: curr?.encodedSmallTitle.replaceAll("🔥", ""),
            };
            break;
        }
      }
      return prev;
    }, {});

    // console.log(
    //   "CrewDetailModal useEffect[crewDetail, scheduleArray], newDayAttributes: ",
    //   newDayAttributes
    // );

    setDayAttributes(newDayAttributes);
  }, [crewDetail, scheduleArray]);

  // -------

  useEffect(() => {
    const newAvailability = attributesList.filter(
      (attr) =>
        attr._kf_payrollNo === crewDetail.payrollNo?.toString() &&
        attr.date === "" &&
        attr.availability.length > 0
    );
    if (newAvailability.length) {
      const newAttributeList = newAvailability[
        newAvailability.length - 1
      ].availability.reduce((prev, curr) => {
        const [day, status] = Object.entries(curr)[0];
        const dayIndex = COLUMN_DAY_ARRAY.findIndex((el) => el === day);

        if (dayIndex >= 0) {
          switch (status) {
            case Statuses.BUFFER:
              prev[dayIndex] = {
                status: Statuses.BUFFER,
              };
              break;
            case Statuses.OFF:
              prev[dayIndex] = {
                status: Statuses.OFF,
              };
              break;

            default:
              prev[dayIndex] = {
                status: Statuses.CUSTOM,
                custom: curr?.encodedSmallTitle?.replaceAll("🔥", ""),
              };
              break;
          }
        }
        return prev;
      }, {});
      setDayAttributesDefault(newAttributeList);
    }
  }, [attributesList, crewDetail]);

  // -------

  useEffect(() => {
    const newCustomValues = weekDatesArray.map(
      (value) => dayAttributes[value]?.custom || ""
    );

    // console.log(
    //   "CrewDetailModal useEffect[weekDatesArray, dayAttributes], newCustomValues: ",
    //   newCustomValues
    // );

    setCustomValues(newCustomValues);
  }, [weekDatesArray, dayAttributes]);

  // ------

  return (
    <Modal onClose={() => setOpenCrewDetailModal({ show: false })}>
      <Modal.Header>Crew: {crewDetail?.Name_Full}</Modal.Header>
      <Modal.Content>
        {crewDetail ? (
          <div className="flex flex-col gap-4 w-[984px]">
            <div className="text-lg font-bold">General Info</div>
            <div className="pb-4 flex gap-2.5">
              <div className="flex-1 grid grid-cols-[100px_auto]">
                <div>Name</div>
                <div className="font-bold">{crewDetail.Name_Full}</div>
                <div>Crew Type</div>
                <div className="font-bold">{crewDetail.RecordType}</div>
                <div>Surface</div>
                <div className="font-bold">{crewDetail.Scheduling_Surface}</div>
                <div>Payroll#</div>
                <div className="font-bold">
                  {dataFormatting_payrollFields(crewDetail.payrollNo)}
                </div>
              </div>
              <div className="flex-1 grid grid-cols-[100px_auto] content-start">
                <div>Phone</div>
                <div className="font-bold">{crewDetail.Phone1}</div>
                <div>Email</div>
                <div className="font-bold text-skin-link">
                  {crewDetail.Email}
                </div>
              </div>
            </div>
            <div className="w-full border-b border-b-yellow-30 ">
              {Object.values(Tabs).map((label, index) => (
                <Button
                  key={index}
                  className={`${
                    activeTab === label
                      ? "bg-opacity-30 font-semibold"
                      : "bg-opacity-5"
                  }  py-2 text-skin-primary`}
                  onClick={() => setActiveTab(label)}
                >
                  {label}
                </Button>
              ))}
            </div>
            <div className="pb-4 ">
              {activeTab !== Tabs.CAPACITIES ? (
                <div className="flex flex-col gap-4">
                  {activeTab === Tabs.AVAILABILITY ? (
                    <div className="flex gap-4 h-6">
                      <Button onClick={() => onWeekToday()}>Today</Button>
                      <WeekPicker
                        value={weekDatesArray[0]}
                        weekBehind={() => onWeekBehind()}
                        weekAhead={() => onWeekAhead()}
                      ></WeekPicker>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="flex gap-2">
                    {COLUMN_DAY_ARRAY.map((day, dayIndex) => (
                      <div className="flex-1" key={dayIndex}>
                        <div
                          className={`${
                            dayIndex < 5 ? "bg-header" : "bg-header-lighter"
                          }
                        text-white border-b-2 border-b-yellow flex justify-center py-2`}
                        >
                          {day}&nbsp;
                          {activeTab === Tabs.AVAILABILITY
                            ? weekDatesArray[dayIndex]
                            : ""}
                        </div>
                        <div className="flex flex-col gap-1 p-2 bg-skin-surface-hard">
                          {Object.values(Statuses).map((value, index) => (
                            <RadioButton
                              key={index}
                              value={value}
                              checked={
                                value === getSelectedStatus(dayIndex)?.status
                              }
                              onChange={(e) =>
                                onChangeStatus(e.target.defaultValue, dayIndex)
                              }
                            >
                              {value}
                            </RadioButton>
                          ))}
                          <div className="w-[100px] mx-auto h-6">
                            <Input
                              placeholder="Custom"
                              value={getCustomValues(dayIndex)}
                              onChange={(e) =>
                                onCustomChange(e.target.value, dayIndex)
                              }
                              onBlur={() => saveCustomStatus(dayIndex)}
                              className="bg-skin-base border border-black border-opacity-20 "
                            ></Input>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <>
                  <div className="flex gap-2">
                    {CAPACITIES_HEADER.map((label, headerIndex) => (
                      <div className="flex-1" key={headerIndex}>
                        <div
                          className={`${
                            headerIndex > 1 && headerIndex < 5
                              ? "bg-header"
                              : headerIndex < 2
                              ? " "
                              : "bg-header-lighter"
                          } ${
                            headerIndex > 1 ? "text-white" : ""
                          } border-b-2 border-b-yellow flex justify-center py-2`}
                        >
                          {label ? label : "\u00A0"}
                        </div>
                        <div
                          className={`${
                            headerIndex > 1 ? "bg-skin-primary" : ""
                          } p-2 shadow-4 flex flex-col gap-2`}
                        >
                          {FILTERS_SURFACE_ARRAY.map(
                            (surface, surfaceIndex) => (
                              <div
                                key={surfaceIndex}
                                className="flex gap-2 h-6 items-center"
                              >
                                {headerIndex === 0 ? surface.name : ""}
                                {headerIndex === 1 ? (
                                  <div className="bg-skin-base">
                                    {capacityValues[surfaceIndex][headerIndex]}
                                  </div>
                                ) : (
                                  ""
                                )}
                                {headerIndex > 1 ? (
                                  <div className="bg-skin-base w-full px-2 text-right leading-6">
                                    {capacityValues[surfaceIndex][headerIndex]}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
          </div>
        ) : (
          "Job not found"
        )}
      </Modal.Content>
      <Modal.Footer>
        <div className="h-8 flex">
          <Button
            color="light"
            className="uppercase w-31"
            onClick={() => setOpenCrewDetailModal({ show: false })}
          >
            Close
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default CrewDetailModal;
