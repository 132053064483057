import { createContext, useContext, useState, useEffect } from "react";
import { AppContext } from "./app.context";
import { ModelContext } from "./model.context";
import { MILLISECONDS_PER_DAY, JOB_FURNITURE_SKULIST } from "../utility/keys";

// - - - - - - - - - - - - - - - - - - - -

const emptyArray = [];

// - - - - - - - - - - - - - - - - - - - -

export const ScheduleContext = createContext();

// - - - - - - - - - -

export const ScheduleContextProvider = ({ children }) => {
  const [scheduleArray, setScheduleArray] = useState(emptyArray);
  const [countsArray, setCountsArray] = useState(emptyArray);
  const [furnitureArray, setFurnitureArray] = useState(emptyArray);
  const [schedulePreparing, setSchedulePreparing] = useState(false);
  const [scheduleNeedsModel, setScheduleNeedsModel] = useState(false);
  const [processingDragNDrop, setProcessingDragNDrop] = useState(false);
  const [dragObject, setDragObject] = useState("");
  const [dropObject, setDropObject] = useState("");
  const [projectListQuery, setProjectListQuery] = useState("");

  const {
    appEnvirons,
    companyCode,
    locationFilter,
    locationArray,
    weekDatesArray,
    createUuid,
    dataFormatting_dateFields,
    dataFormatting_payrollFields,
    dataFormatting_encodedSmallTitleNew,
    dataFormatting_encodedLargeTitleNew,
    jobTitleConfig,
  } = useContext(AppContext);
  const {
    mongoUser,
    projectsList,
    filteredProjectsList,
    getProjectsByCompany,
    projectsLoading,
    candidatesList,
    attributesList,
    getCandidatesByCompany,
    getAttributesByLocation,
    candidatesLoading,
    attributesLoading,
    userDocument,
    getUniqueLocations,
    locationsLoading,
    updateFilteredProjectsList,
    setUserPrefsById,
    setScheduleById,
    createCrewQueryList,
  } = useContext(ModelContext);

  // - - - - - - - - - -

  const processDragNDrop = () => {
    console.log(
      "ScheduleContext processDragNDrop, drag: ",
      dragObject,
      ", drop: ",
      dropObject
    );

    const foundIndex = projectsList.findIndex((currentValue, index, arr) => {
      return currentValue.id === dragObject;
    });
    if (foundIndex !== -1) {
      var foundDocument = projectsList[foundIndex];
      var dayDifference = 0;

      // unassigned jobs will likely not yet have any dates yet
      if (foundDocument.date_scheduled !== "") {
        // empty endDate not allowed - make it the same as startDate
        if (foundDocument.date_estimatedCompletion === "") {
          foundDocument.date_estimatedCompletion = foundDocument.date_scheduled;
        }

        // date calcs
        const timeDifference =
          new Date(foundDocument.date_estimatedCompletion).getTime() -
          new Date(foundDocument.date_scheduled).getTime();
        dayDifference = Math.round(timeDifference / (1000 * 3600 * 24));
        // console.log(
        //   "ScheduleContext processDragNDrop dayDifference: ",
        //   dayDifference
        // );
      }

      if (dropObject.slice(0, 10) === "Unassigned") {
        switch (dropObject) {
          case "Unassigned_Mon":
            foundDocument.date_scheduled = weekDatesArray[0];
            foundDocument.date_estimatedCompletion = new Date(
              Date.parse(weekDatesArray[0]) +
                MILLISECONDS_PER_DAY * dayDifference
            ).toLocaleDateString("en-us");
            foundDocument.installer = "";
            foundDocument.statusCode = 4;
            break;
          case "Unassigned_Tue":
            foundDocument.date_scheduled = weekDatesArray[1];
            foundDocument.date_estimatedCompletion = new Date(
              Date.parse(weekDatesArray[1]) +
                MILLISECONDS_PER_DAY * dayDifference
            ).toLocaleDateString("en-us");
            foundDocument.installer = "";
            foundDocument.statusCode = 4;
            break;
          case "Unassigned_Wed":
            foundDocument.date_scheduled = weekDatesArray[2];
            foundDocument.date_estimatedCompletion = new Date(
              Date.parse(weekDatesArray[2]) +
                MILLISECONDS_PER_DAY * dayDifference
            ).toLocaleDateString("en-us");
            foundDocument.installer = "";
            foundDocument.statusCode = 4;
            break;
          case "Unassigned_Thr":
            foundDocument.date_scheduled = weekDatesArray[3];
            foundDocument.date_estimatedCompletion = new Date(
              Date.parse(weekDatesArray[3]) +
                MILLISECONDS_PER_DAY * dayDifference
            ).toLocaleDateString("en-us");
            foundDocument.installer = "";
            foundDocument.statusCode = 4;
            break;
          case "Unassigned_Fri":
            foundDocument.date_scheduled = weekDatesArray[4];
            foundDocument.date_estimatedCompletion = new Date(
              Date.parse(weekDatesArray[4]) +
                MILLISECONDS_PER_DAY * dayDifference
            ).toLocaleDateString("en-us");
            foundDocument.installer = "";
            foundDocument.statusCode = 4;
            break;
          case "Unassigned_Sat":
            foundDocument.date_scheduled = weekDatesArray[5];
            foundDocument.date_estimatedCompletion = new Date(
              Date.parse(weekDatesArray[5]) +
                MILLISECONDS_PER_DAY * dayDifference
            ).toLocaleDateString("en-us");
            foundDocument.installer = "";
            foundDocument.statusCode = 4;
            break;
          case "Unassigned_Sun":
            foundDocument.date_scheduled = weekDatesArray[6];
            foundDocument.date_estimatedCompletion = new Date(
              Date.parse(weekDatesArray[6]) +
                MILLISECONDS_PER_DAY * dayDifference
            ).toLocaleDateString("en-us");
            foundDocument.installer = "";
            foundDocument.statusCode = 4;
            break;
          case "Unassigned_RTS":
            foundDocument.date_scheduled = "";
            foundDocument.date_estimatedCompletion = "";
            foundDocument.installer = "";
            foundDocument.statusCode = 3;
            break;

          default:
            console.log(
              "ScheduleContext processDragNDrop 'Unassigned' switch ERROR; no case matched..."
            );
            break;
        }
        console.log(
          "ScheduleContext processDragNDrop 'Unassigned' switch, date_scheduled: ",
          foundDocument.date_scheduled,
          ", date_estimatedCompletion: ",
          foundDocument.date_estimatedCompletion,
          ", statusCode: ",
          foundDocument.statusCode
        );
      } else {
        const droppedPayrollNo = dropObject.slice(0, 7);
        const droppedColumnIndex = dropObject.slice(8);
        foundDocument.date_scheduled = weekDatesArray[droppedColumnIndex];
        foundDocument.date_estimatedCompletion = new Date(
          Date.parse(weekDatesArray[droppedColumnIndex]) +
            MILLISECONDS_PER_DAY * dayDifference
        ).toLocaleDateString("en-us");
        foundDocument.installer = droppedPayrollNo;
        foundDocument.statusCode = 4;
        console.log(
          "ScheduleContext processDragNDrop 'Assigned' switch, payrollNo: ",
          droppedPayrollNo,
          ", colIndex: ",
          droppedColumnIndex,
          ", date_scheduled: ",
          foundDocument.date_scheduled,
          ", date_estimatedCompletion: ",
          foundDocument.date_estimatedCompletion,
          ", statusCode: ",
          foundDocument.statusCode
        );
      }
      // update MongoDB with scheduledDate
      //
      //
      //     THIS CHANGES MONGODB DATA, WHICH THEN TRIGGERS FUNCTION TO UPDATE FILEMAKER VIA HOOKDECK
      //
      if (
        appEnvirons.appEnv === "development" ||
        appEnvirons.appEnv === "uat"
      ) {
        //
        // DEV / UAT
        //
        console.log(
          "ScheduleContext processDragNDrop setScheduleById->MongoAtlasUpdate->hookDeck->FMS because appEnvirons: ",
          appEnvirons.appEnv
        );
        setScheduleById(
          foundDocument._id.toString(),
          foundDocument.installer,
          foundDocument.date_scheduled,
          foundDocument.date_estimatedCompletion,
          foundDocument.arrivalTime,
          foundDocument.statusCode
        );
        //
      } else {
        //
        // PROD
        //
        // appEnvirons.appEnv === "production"
        console.log(
          "ScheduleContext processDragNDrop setScheduleById->MongoAtlasUpdate->hookDeck->FMS because appEnvirons: ",
          appEnvirons.appEnv
        );
        setScheduleById(
          foundDocument._id.toString(),
          foundDocument.installer,
          foundDocument.date_scheduled,
          foundDocument.date_estimatedCompletion,
          foundDocument.arrivalTime,
          foundDocument.statusCode
        );
      }
      //
      //     </THIS ...>
      //
      //
      createCountsArray();
      createFurnitureArray();
      updateFilteredProjectsList(projectsList, locationFilter);

      // console.log(
      //   "ScheduleContext foundIndex: ",
      //   foundIndex,
      //   ", id: ",
      //   foundDocument._id.toString(),
      //   ", scheduled: ",
      //   foundDocument.date_scheduled,
      //   ", document: ",
      //   foundDocument
      // );
    } else {
      console.log(
        "ScheduleContext processDragNDrop ERROR; record not found by dragObject(id)..."
      );
    }

    setDragObject("");
    setDropObject("");
    setProcessingDragNDrop(false);
  };

  // - - - - - - - - - -

  const onScheduleNeedsModel = () => {
    // schedule needs updated Model data
    if (mongoUser) {
      // only if there is a logged-in user
      setSchedulePreparing(true);
      getUniqueLocations(companyCode);
      getCandidatesByCompany(companyCode, locationFilter);
      getProjectsByCompany(companyCode, locationFilter);
      getAttributesByLocation(companyCode, locationFilter);
      setUserPrefsById(mongoUser.id);
    } else {
      console.log(
        "ScheduleContext onScheduleNeedsModel, NO user so not proceeding with model loading..."
      );
    }
  };

  // - - - - - - - - - -

  const createScheduleArray = () => {
    // Model received, now transform it
    // added much later: weekdaysArray has changed (user changed week) so re-create and insert in availability defaults
    //

    // loop thru filtered documents
    const newScheduleArray = filteredProjectsList.map((document, index) => {
      var newJob = {};
      // calculate material type qty (yds or sqft)

      let materialsCount = document.Materials ? document.Materials.length : 0;
      var materialsIndex = 0;
      var currentQty = 0;
      while (materialsCount > materialsIndex) {
        // loop thru materials
        const originalQty = currentQty;
        const currentMaterial = document.Materials[materialsIndex];
        const currentMaterialType = currentMaterial["KI_AQ » Materials::type"];
        // const currentMaterialQty = parseFloat(
        //   currentMaterial["KI_AQ » Materials::qty"]
        // ).toFixed(1);
        const currentMaterialYds = parseFloat(
          currentMaterial["KI_AQ » Materials::total_yds"]
        ).toFixed(0);
        const currentMaterialSqFt = parseFloat(
          currentMaterial["KI_AQ » Materials::total_sqft"]
        ).toFixed(0);
        switch (currentMaterialType) {
          case "Carpet":
            // NOTE: The unary plus operator (+) precedes its operand and evaluates to its operand but attempts to convert it into a number, if it isn't already.
            currentQty += +currentMaterialYds;
            break;
          case "Vinyl":
            currentQty += +currentMaterialSqFt;
            break;
          case "Plank":
            currentQty += +currentMaterialSqFt;
            break;
          case "Ceramic":
            currentQty += +currentMaterialSqFt;
            break;
          case "Wood":
            currentQty += +currentMaterialSqFt;
            break;
          case "Backsplash":
            currentQty += +currentMaterialSqFt;
            break;
          case "Pad":
            break;
          default:
            currentQty = originalQty;
        }
        materialsIndex += 1;
      }

      // calculate Furniture
      const lineItemsCount = document.InvoiceLineItems
        ? document.InvoiceLineItems.length
        : 0;
      var lineItemsIndex = 0;
      var currentFurniture = false;
      while (lineItemsCount > lineItemsIndex) {
        // loop thru lineItems
        const currentLineItem = document.InvoiceLineItems[lineItemsIndex];
        const currentLineItemSku =
          currentLineItem["KI_AQ » InvoiceLineItems::sku"];
        if (JOB_FURNITURE_SKULIST.includes(parseInt(currentLineItemSku))) {
          currentFurniture = true;
          // console.log(
          //   "ScheduleContext createScheduleArray, lineItem SKU matched furnitureSkuList, sku: ",
          //   currentLineItemSku,
          //   ", invoceNo: ",
          //   document.invoiceNo
          // );
        }

        lineItemsIndex += 1;
      }

      // compose final object
      newJob.id = document.id;
      newJob.mongoId = document.mongoId;
      newJob.companyCode = document.companyCode;
      newJob.location = document.location;
      newJob.invoiceNo = document.invoiceNo;
      newJob.projectNo = document.lowes_projectNo;
      // date_scheduled in ActiveQueue:      If ( $classification = "Detail"; KellerInteriors::EstimateScheduledDate ; KellerInteriors::ScheduledDate )
      newJob.start = document.date_scheduled
        ? dataFormatting_dateFields(document.date_scheduled)
        : "";
      // date_estimatedCompletion in ActiveQueue:      If ( $classification = "Detail"; "" ; KellerInteriors::EstimatedCompletionDate )
      newJob.end = document.date_estimatedCompletion
        ? dataFormatting_dateFields(document.date_estimatedCompletion)
        : newJob.start;
      newJob.arrival = document.arrival_time_text
        ? document.arrival_time_text
        : ""; // FM field is from ActiveQueue, which has no "arrivalTime" field
      newJob.classification = document.classification;
      newJob.storeNo = document.lowes_storeNo;
      newJob.surface = document.surface;
      newJob.statusCode = document.statusCode;
      newJob.qty = currentQty;
      newJob.firstName = document.customer_firstName;
      newJob.lastName = document.customer_lastName;
      newJob.cellPhone = document.customer_cellPhone;
      newJob.address = document.customer_address;
      // installer in ActiveQueue:           If ( $classification = "Detail"; KellerInteriors::Detailer: ; KellerInteriors::Installer: )
      newJob.installer =
        document.installer !== ""
          ? dataFormatting_payrollFields(document.installer)
          : "";
      newJob.expressShip = document.expressShipFlag;
      newJob.furniture = currentFurniture;
      // If ( customerPortal_Scheduling > 0 (=1 means text has been sent) and portalState ≥ 300) then customer has prescheduled
      //       decided to drop custPort_Scheduling, since portalState is the better qualifier
      //    portalState = 100 : text sent, Welcome screen
      //    portalState = 200 : cust sees Calendar chooser
      //    portalState = 300 : cust has scheduled job
      newJob.prescheduled = document.portalState >= 300 ? true : false;
      // rush = prescheduled but not finalized/verified by staff
      newJob.rush =
        newJob.prescheduled && document.date_arranged === "" ? true : false;
      // if scheduled Job, and a required field is empty, show IMS warning
      newJob.imsWarning =
        document.classification !== "Detail" &&
        document.date_scheduled !== "" &&
        (document.date_estimatedCompletion === "" ||
          newJob.arrival === "" ||
          document.lowes_projectNo === "" ||
          document.installer === "")
          ? true
          : false;

      // add Job-specific Attributes, if they exist (primary key is invoiceNo instead of _kf_payrollNo)
      newJob.jobAttributes = attributesList.find(
        (obj) => obj.invoiceNo === document.invoiceNo
      );

      newJob.flags = `${newJob.expressShip === 1 ? "📦" : ""}${
        newJob.furniture ? "🪑" : ""
      }${newJob.prescheduled ? "📅" : ""}${newJob.rush ? "⚡️" : ""}${
        newJob.jobAttributes?.expedite ? "🏃🏽‍♂️" : ""
      }${newJob.jobAttributes?.distant ? "✈️" : ""}${
        newJob.imsWarning ? "🛑" : ""
      }`;

      // newJob.encodedSmallTitle = dataFormatting_encodedSmallTitle(newJob);
      newJob.encodedSmallTitle = dataFormatting_encodedSmallTitleNew(
        newJob,
        jobTitleConfig
      );
      // newJob.encodedLargeTitle = dataFormatting_encodedLargeTitle(newJob);
      newJob.encodedLargeTitle = dataFormatting_encodedLargeTitleNew(
        newJob,
        jobTitleConfig
      );
      newJob.index = index;

      return newJob;
    }); // filteredProjectsList.map
    // console.log("ScheduleContext createScheduleArray: ", newScheduleArray);

    // add Availability Defaults Attribute data to scheduleArray
    const newDefaultArray = [];
    const defaultAttributeList = attributesList.filter(
      (doc) =>
        doc._kf_payrollNo !== "" &&
        doc.date === "" &&
        doc.availability.length > 0
    );
    if (defaultAttributeList?.length > 0) {
      console.log(
        `ScheduleContext defaultAttributeList(${defaultAttributeList.length}): `,
        defaultAttributeList
      );
      defaultAttributeList.forEach((attributeDoc, attributeIndex) => {
        const defaultAttributeObject = attributeDoc;
        const defaultsArray = defaultAttributeObject.availability;
        const daysAffected = defaultsArray.length;
        // console.log(
        //   `ScheduleContext defaultAttributeObject: `,
        //   defaultAttributeObject
        // );
        if (daysAffected > 0) {
          // this attribute will NOT be added to the Attributes Collection
          //   therefore no DOCUMENT_ATTRIBUTES_TEMPLATE (keys.js) template will be used
          var newAttribute = {};
          var newAttributeTemplate = {};
          newAttributeTemplate.companyCode = defaultAttributeObject.companyCode;
          newAttributeTemplate.location = defaultAttributeObject.location;
          newAttributeTemplate.installer =
            defaultAttributeObject.installer !== ""
              ? dataFormatting_payrollFields(
                  defaultAttributeObject._kf_payrollNo
                )
              : "";
          newAttributeTemplate.start = "";
          newAttributeTemplate.end = "";
          newAttributeTemplate.id = createUuid();
          newAttributeTemplate.invoiceNo = "";
          newAttributeTemplate.encodedLargeTitle = "";
          newAttributeTemplate.encodedSmallTitle = "";
          newAttributeTemplate.locked = false;
          newAttributeTemplate.distant = false;
          newAttributeTemplate.expedite = false;
          newAttributeTemplate.attribute = true;

          weekDatesArray.forEach((date, index) => {
            switch (index) {
              case 0:
                if (defaultsArray.find((day) => day.Mon)) {
                  if (
                    newScheduleArray.find(
                      (job) =>
                        job.start === date &&
                        job.installer === newAttributeTemplate.installer
                    )
                  ) {
                    // don't create a default attribute if a job has already been assigned
                  } else {
                    newAttribute = { ...newAttributeTemplate };
                    newAttribute.start = date;
                    newAttribute.end = date;
                    newAttribute.encodedSmallTitle = defaultsArray.find(
                      (day) => day.Mon
                    ).Mon;
                    newAttribute.encodedLargeTitle = defaultsArray.find(
                      (day) => day.Mon
                    ).Mon;
                    newDefaultArray.push(newAttribute);
                  }
                }
                break;

              case 1:
                if (defaultsArray.find((day) => day.Tue)) {
                  if (
                    newScheduleArray.find(
                      (job) =>
                        job.start === date &&
                        job.installer === newAttributeTemplate.installer
                    )
                  ) {
                    // don't create a default attribute if a job has already been assigned
                  } else {
                    newAttribute = { ...newAttributeTemplate };
                    newAttribute.start = date;
                    newAttribute.end = date;
                    newAttribute.encodedSmallTitle = defaultsArray.find(
                      (day) => day.Tue
                    ).Tue;
                    newAttribute.encodedLargeTitle = defaultsArray.find(
                      (day) => day.Tue
                    ).Tue;
                    newDefaultArray.push(newAttribute);
                  }
                }
                break;

              case 2:
                if (defaultsArray.find((day) => day.Wed)) {
                  if (
                    newScheduleArray.find(
                      (job) =>
                        job.start === date &&
                        job.installer === newAttributeTemplate.installer
                    )
                  ) {
                    // don't create a default attribute if a job has already been assigned
                  } else {
                    newAttribute = { ...newAttributeTemplate };
                    newAttribute.start = date;
                    newAttribute.end = date;
                    newAttribute.encodedSmallTitle = defaultsArray.find(
                      (day) => day.Wed
                    ).Wed;
                    newAttribute.encodedLargeTitle = defaultsArray.find(
                      (day) => day.Wed
                    ).Wed;
                    newDefaultArray.push(newAttribute);
                  }
                }
                break;

              case 3:
                if (defaultsArray.find((day) => day.Thr)) {
                  if (
                    newScheduleArray.find(
                      (job) =>
                        job.start === date &&
                        job.installer === newAttributeTemplate.installer
                    )
                  ) {
                    // don't create a default attribute if a job has already been assigned
                  } else {
                    newAttribute = { ...newAttributeTemplate };
                    newAttribute.start = date;
                    newAttribute.end = date;
                    newAttribute.encodedSmallTitle = defaultsArray.find(
                      (day) => day.Thr
                    ).Thr;
                    newAttribute.encodedLargeTitle = defaultsArray.find(
                      (day) => day.Thr
                    ).Thr;
                    newDefaultArray.push(newAttribute);
                  }
                }
                break;

              case 4:
                if (defaultsArray.find((day) => day.Fri)) {
                  if (
                    newScheduleArray.find(
                      (job) =>
                        job.start === date &&
                        job.installer === newAttributeTemplate.installer
                    )
                  ) {
                    // don't create a default attribute if a job has already been assigned
                  } else {
                    newAttribute = { ...newAttributeTemplate };
                    newAttribute.start = date;
                    newAttribute.end = date;
                    newAttribute.encodedSmallTitle = defaultsArray.find(
                      (day) => day.Fri
                    ).Fri;
                    newAttribute.encodedLargeTitle = defaultsArray.find(
                      (day) => day.Fri
                    ).Fri;
                    newDefaultArray.push(newAttribute);
                  }
                }
                break;

              case 5:
                if (defaultsArray.find((day) => day.Sat)) {
                  if (
                    newScheduleArray.find(
                      (job) =>
                        job.start === date &&
                        job.installer === newAttributeTemplate.installer
                    )
                  ) {
                    // don't create a default attribute if a job has already been assigned
                  } else {
                    newAttribute = { ...newAttributeTemplate };
                    newAttribute.start = date;
                    newAttribute.end = date;
                    newAttribute.encodedSmallTitle = defaultsArray.find(
                      (day) => day.Sat
                    ).Sat;
                    newAttribute.encodedLargeTitle = defaultsArray.find(
                      (day) => day.Sat
                    ).Sat;
                    newDefaultArray.push(newAttribute);
                  }
                }
                break;

              case 6:
                if (defaultsArray.find((day) => day.Sun)) {
                  if (
                    newScheduleArray.find(
                      (job) =>
                        job.start === date &&
                        job.installer === newAttributeTemplate.installer
                    )
                  ) {
                    // don't create a default attribute if a job has already been assigned
                  } else {
                    newAttribute = { ...newAttributeTemplate };
                    newAttribute.start = date;
                    newAttribute.end = date;
                    newAttribute.encodedSmallTitle = defaultsArray.find(
                      (day) => day.Sun
                    ).Sun;
                    newAttribute.encodedLargeTitle = defaultsArray.find(
                      (day) => day.Sun
                    ).Sun;
                    newDefaultArray.push(newAttribute);
                  }
                }
                break;

              default:
                break;
            }
          });
        }
      }); //
    } // if (defaultAttributeObject?.length > 0)
    console.log(`ScheduleContext newDefaultArray: `, newDefaultArray);

    // add Attributes data to scheduleArray
    const newAttributeArray = attributesList
      .filter((document, index) => {
        // filter out Job-specific Attributes (primary key is invoiceNo instead of _kf_payrollNo)
        return document._kf_payrollNo !== "" && document.date !== "";
      })
      .map((document, index) => {
        var newJob = {};
        newJob._id = document._id;
        newJob.id = document.id;
        newJob.companyCode = document.companyCode;
        newJob.location = document.location;
        // NOTE: since Attribute Docs will now handle Installer/Date Docs AND Job Docs
        //          we want to hide Docs without payrollNos from schedule Cells
        //          so to do that start & installer has to be blank
        //          If only installer is blank then the date causes it to appear in Unassigned row
        newJob.start =
          document.date && document._kf_payrollNo !== ""
            ? dataFormatting_dateFields(document.date)
            : "";
        newJob.end = newJob.start;
        newJob.installer =
          document._kf_payrollNo !== ""
            ? dataFormatting_payrollFields(document._kf_payrollNo)
            : "";
        newJob.encodedSmallTitle = document.encodedSmallTitle;
        newJob.encodedLargeTitle = document.encodedLargeTitle;
        // newJob.distant = false; // not used here in payroll Attributes
        // newJob.expedite = false; // not used here in payroll Attributes
        newJob.locked = document.locked;
        newJob.availability = document.availability;
        newJob.index = index;
        newJob.attribute = true; // added for UI (JobTile), now that old attributes.json blob has been deprecated
        return newJob;
      });
    // console.log("ScheduleContext newAttributeArray: ", newAttributeArray);

    // finalize data
    const combinedArray = [
      ...newScheduleArray,
      ...newAttributeArray,
      ...newDefaultArray,
    ];
    console.log(
      `ScheduleContext combinedArray new ScheduleArray (week of ${weekDatesArray[0]}): `,
      combinedArray
    );
    setScheduleArray(combinedArray);
    setSchedulePreparing(false);
  };

  // - - - - - - - - - -

  // not only create the countsArray, but also find/handle "invisible" jobs
  //             i.e. scheduled, assigned job(s) for installers not in this location
  const createCountsArray = () => {
    var newCountsArray = weekDatesArray.map((date, index) => {
      var newCountObject = {
        date: date,
        softJobs: 0,
        softSlots: 0,
        softFilledSlots: new Set(),
        hardJobs: 0,
        hardSlots: 0,
        hardFilledSlots: new Set(),
      };
      return newCountObject;
    });

    // loop thru unfiltered project documents
    projectsList.forEach((document, projectIndex) => {
      const classification = document.classification;
      const start =
        document.date_scheduled !== ""
          ? dataFormatting_dateFields(document.date_scheduled)
          : "";
      var end =
        document.date_estimatedCompletion !== ""
          ? dataFormatting_dateFields(document.date_estimatedCompletion)
          : "";
      if (end === "") {
        end = start;
      }
      const installer =
        document.installer !== ""
          ? dataFormatting_payrollFields(document.installer)
          : "";

      if (start !== "") {
        // loop thru columns to determine which is applicable
        weekDatesArray.forEach((date, dateIndex) => {
          if (
            (new Date(start).getTime() >= new Date(date).getTime() &&
              new Date(end).getTime() <= new Date(date).getTime()) ||
            (new Date(date).getTime() >= new Date(start).getTime() &&
              new Date(date).getTime() <= new Date(end).getTime())
          ) {
            if (installer !== "") {
              // find applicable installer document
              const matchedCandidate = candidatesList.filter((candidate) => {
                const formattedPayrollNo = dataFormatting_payrollFields(
                  candidate.payrollNo
                );
                return formattedPayrollNo === installer;
              });
              if (matchedCandidate.length > 0) {
                // determine which surface type to increment applicable count
                if (
                  matchedCandidate[0].Scheduling_Surface === "Soft Surface" &&
                  classification !== "Detail"
                ) {
                  newCountsArray[dateIndex].softJobs += 1;
                  newCountsArray[dateIndex].softFilledSlots.add(
                    document.installer
                  );
                } else if (
                  matchedCandidate[0].Scheduling_Surface === "Hard Surface" &&
                  classification !== "Detail"
                ) {
                  newCountsArray[dateIndex].hardJobs += 1;
                  newCountsArray[dateIndex].hardFilledSlots.add(
                    document.installer
                  );
                }
              }
            } else {
              // installer == "" => Unassigned Scheduled
              switch (document.surface) {
                case "Carpet":
                  newCountsArray[dateIndex].softJobs += 1;
                  break;
                case "Vinyl":
                  newCountsArray[dateIndex].hardJobs += 1;
                  break;
                case "Plank":
                  newCountsArray[dateIndex].hardJobs += 1;
                  break;
                case "Ceramic":
                  newCountsArray[dateIndex].hardJobs += 1;
                  break;
                case "Wood":
                  newCountsArray[dateIndex].hardJobs += 1;
                  break;
                case "Backsplash":
                  newCountsArray[dateIndex].hardJobs += 1;
                  break;
                case "Pad":
                  break;
                default:
                  break;
              }
            }
          }
        });
      }
    });

    // loop thru candidates
    candidatesList.forEach((document, candidateIndex) => {
      const surface = document.Scheduling_Surface;
      if (surface === "Soft Surface") {
        weekDatesArray.forEach((date, dateIndex) => {
          newCountsArray[dateIndex].softSlots += 1;
        });
      } else if (surface === "Hard Surface") {
        weekDatesArray.forEach((date, dateIndex) => {
          newCountsArray[dateIndex].hardSlots += 1;
        });
      }
    });

    console.log("ScheduleContext createCountsArray: ", newCountsArray);
    setCountsArray(newCountsArray);
  };

  // - - - - - - - - - -

  const createFurnitureArray = () => {
    if (
      weekDatesArray.length > 0 &&
      candidatesList.length > 0 &&
      projectsList.length > 0
    ) {
      var newFurnitureArray = [];
      for (let index = 0; index < weekDatesArray.length; index++) {
        const date = weekDatesArray[index];
        const newFurnitureObject = {
          date: date,
          softSurface: {
            available: 0,
            filled: 0,
          },
          hardSurface: {
            available: 0,
            filled: 0,
          },
        };
        newFurnitureArray.push(newFurnitureObject);
      }

      // loop thru candidates
      for (
        let candidateIndex = 0;
        candidateIndex < candidatesList.length;
        candidateIndex++
      ) {
        const document = candidatesList[candidateIndex];
        const surface = document.Scheduling_Surface;
        if (surface === "Soft Surface") {
          for (
            let dateIndex = 0;
            dateIndex < weekDatesArray.length;
            dateIndex++
          ) {
            if (document.capacity_carpet_once_furniture > 0) {
              newFurnitureArray[dateIndex].softSurface.available += 1;
            }
          }
        } else if (surface === "Hard Surface") {
          for (
            let dateIndex = 0;
            dateIndex < weekDatesArray.length;
            dateIndex++
          ) {
            if (
              document.capacity_vinyl_once_furniture > 0 ||
              document.capacity_vinylSheet_once_furniture > 0 ||
              document.capacity_tile_once_furniture > 0 ||
              document.capacity_backsplash_once_furniture > 0 ||
              document.capacity_floating_once_furniture > 0 ||
              document.capacity_glueDown_once_furniture > 0 ||
              document.capacity_nailDown_once_furniture > 0
            ) {
              newFurnitureArray[dateIndex].hardSurface.available += 1;
            }
          }
        }
      }
      // console.log(
      //   "ScheduleContext newFurnitureArray (after CandidatesList): ",
      //   newFurnitureArray
      // );

      // loop thru unfiltered project documents
      for (
        let projectIndex = 0;
        projectIndex < scheduleArray.length;
        projectIndex++
      ) {
        const document = scheduleArray[projectIndex];
        const surface = document?.surface;
        const start = document?.start;
        var end = document?.end ? document?.end : start;

        if (
          document.furniture &&
          document.location === locationFilter &&
          document.classification !== "Detail" &&
          document.installer !== "" &&
          document.surface !== "UNK" &&
          start !== ""
        ) {
          // loop thru columns to determine which is applicable
          for (
            let dateIndex = 0;
            dateIndex < weekDatesArray.length;
            dateIndex++
          ) {
            const date = weekDatesArray[dateIndex];
            if (
              (new Date(start).getTime() >= new Date(date).getTime() &&
                new Date(end).getTime() <= new Date(date).getTime()) ||
              (new Date(date).getTime() >= new Date(start).getTime() &&
                new Date(date).getTime() <= new Date(end).getTime())
            ) {
              if (surface === "Carpet") {
                newFurnitureArray[dateIndex].softSurface.filled += 1;
              } else {
                newFurnitureArray[dateIndex].hardSurface.filled += 1;
                // console.log(
                //   `DEBUG  ScheduleContext newFurnitureArray[${dateIndex}] added 1 HARD: `,
                //   document
                // );
              }
            }
          }
        }
      }

      console.log("ScheduleContext newFurnitureArray: ", newFurnitureArray);
      setFurnitureArray(newFurnitureArray);
    }
  };

  // - - - - - - - - - - - - - - - - -  EFFECT HOOKS  - - - - - - - - - - - - - - - - - -
  //
  // companyCode change                     -> setScheduleNeedsModel
  // filteredProjectsList change            -> createScheduleArray
  // projectsList, candidatesList change    -> setScheduleNeedsModel
  // attributesList, locationArray change   -> setScheduleNeedsModel
  // scheduleArray change                   -> createCountsArray, createFurnitureArray
  // weekDatesArray change                  -> createScheduleArray
  // scheduleNeedsModel change              -> onScheduleNeedsModel
  // dropObject change                      -> processDragNDrop
  //

  // - - - - - - - - - -

  useEffect(() => {
    if (userDocument.id && !scheduleNeedsModel) {
      // companyCode has updated
      console.log("ScheduleContext useEffect[companyCode]...(request Model)");
      setScheduleNeedsModel(true);
    } else {
      console.log(
        "ScheduleContext useEffect[companyCode], no userDoc.id or already scheduleNeedsModel..."
      );
    }
  }, [companyCode]);

  // - - - - - - - - - -

  useEffect(() => {
    // filteredProjectsList has changed
    console.log(
      "ScheduleContext useEffect[filteredProjectsList]...(createScheduleArray)"
    );
    createScheduleArray();
  }, [filteredProjectsList]);

  // - - - - - - - - - -

  useEffect(() => {
    // projectsList or candidatesList or attributesList or locationArray has updated
    // if (!crewQueryCreated && !projectsLoading && !candidatesLoading) {
    //   createCrewQueryList();
    // }
    if (
      scheduleNeedsModel &&
      !projectsLoading &&
      !candidatesLoading &&
      !attributesLoading &&
      !locationsLoading
    ) {
      console.log(
        "ScheduleContext useEffect[projectsList, candidatesList, attributesList, locationArray, loading=false]...(done loading all lists)"
      );
      setScheduleNeedsModel(false);
      createCrewQueryList();
    } else {
      console.log(
        "ScheduleContext useEffect[projectsList, candidatesList, attributesList, locationArray]...not done loading or NOT scheduleNeedsModel"
      );
      createScheduleArray();
    }
  }, [
    projectsList,
    candidatesList,
    attributesList,
    locationArray,
    jobTitleConfig,
  ]);

  // - - - - - - - - - -

  useEffect(() => {
    console.log(
      "ScheduleContext useEffect[scheduleArray]...(createCountsArray, createFurnitureArray)"
    );
    createCountsArray();
    createFurnitureArray();
  }, [scheduleArray]);

  // - - - - - - - - - -

  useEffect(() => {
    // added this useEffect for when week changes (so that we can insert availability defaults into ScheduleArray),
    //    and removing weekDatesArray changes from above useEffect as to not duplicate
    console.log(
      "ScheduleContext useEffect[weekDatesArray]...(createScheduleArray)"
    );
    createScheduleArray();
  }, [weekDatesArray]);

  // - - - - - - - - - -

  useEffect(() => {
    // scheduleNeedsModel has updated
    if (scheduleNeedsModel && !schedulePreparing) {
      console.log(
        "ScheduleContext useEffect[scheduleNeedsModel=true]...(request Model)"
      );
      onScheduleNeedsModel();
    } else {
      console.log(
        "ScheduleContext useEffect[scheduleNeedsModel], not requesting Model; scheduleNeedsModel: ",
        scheduleNeedsModel,
        "; schedulePreparing: ",
        schedulePreparing
      );
    }
  }, [scheduleNeedsModel]);

  // - - - - - - - - - -

  useEffect(() => {
    if (dropObject !== "" && !processingDragNDrop) {
      setProcessingDragNDrop(true);
      processDragNDrop();
    }
  }, [dropObject]);

  // - - - - - - - - - - - - - - - - -  CONTEXT RETURN  - - - - - - - - - - - - - - - - - -

  return (
    <ScheduleContext.Provider
      value={{
        schedulePreparing,
        scheduleArray,
        countsArray,
        furnitureArray,
        scheduleNeedsModel,
        dragObject,
        dropObject,
        processingDragNDrop,
        projectListQuery,
        setSchedulePreparing,
        setScheduleArray,
        setCountsArray,
        setFurnitureArray,
        setScheduleNeedsModel,
        setDragObject,
        setDropObject,
        setProcessingDragNDrop,
        setProjectListQuery,
        createScheduleArray,
        createCountsArray,
        createFurnitureArray,
      }}
    >
      {children}
    </ScheduleContext.Provider>
  );
};
