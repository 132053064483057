import React from "react";

const getChildrenOnDisplayName = (children, displayName) =>
  React.Children.map(children, (child) =>
    child.type.displayName === displayName ? child : null
  );

const Input = ({
  children,
  className,
  placeholder,
  value,
  onChange,
  onEnter,
  onBlur,
  disabled,
  defaultValue,
  type,
}) => {
  const suffix = getChildrenOnDisplayName(children, "suffix");

  // - - - - - - - - - -

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && typeof onEnter === "function") {
      onEnter(event);
    }
  };

  // - - - - - - - - - -

  const blur = (event) => {
    if (typeof onBlur === "function") {
      onBlur(event);
    }
  };

  // - - - - - - - - - -

  return (
    <div
      tabIndex={1}
      className={`flex gap-2.5 h-full px-2 focus-within:outline outline-2 outline-black ${className}`}
    >
      <input
        className={`${
          type === "number" ? "text-right" : ""
        } h-full w-full bg-inherit outline-none`}
        type="text"
        placeholder={placeholder}
        value={value}
        defaultValue={defaultValue}
        onChange={onChange}
        onBlur={blur}
        onKeyDown={handleKeyDown}
        disabled={disabled}
      />
      {suffix ? <div className="flex gap-2.5 items-center">{suffix}</div> : ""}
    </div>
  );
};

const Suffix = ({ children }) => <>{children}</>;

Suffix.displayName = "suffix";
Input.Suffix = Suffix;

export default Input;
