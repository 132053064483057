import React from "react";
import ReactDOM from "react-dom/client";
import { MsalProvider } from "@azure/msal-react";
import {
  BrowserCacheLocation,
  PublicClientApplication,
} from "@azure/msal-browser";
import "./w3.css";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AppContextProvider } from "./context/app.context";
import { ModelContextProvider } from "./context/model.context";
import { ScheduleContextProvider } from "./context/schedule.context";
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";

// LogRocket
LogRocket.init("pubdu4/scheduler");
// after calling LogRocket.init()
setupLogRocketReact(LogRocket);

// MSAL configuration
const configuration = {
  auth: {
    clientId: "4ae8c1ac-41df-46bb-a881-011a10d1b09b",
    authority:
      "https://login.microsoftonline.com/3d199282-cde8-4234-bab6-ef614f1313af",
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
  },
};

const msalInstance = new PublicClientApplication(configuration);

msalInstance.initialize().then(() => {
  console.log("index.js MSAL initialize");
  // Default to using the first account if no account is active on page load

  if (
    !msalInstance.getActiveAccount() &&
    msalInstance.getAllAccounts().length > 0
  ) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }

  const root = ReactDOM.createRoot(document.getElementById("root"));
  root.render(
    // <React.StrictMode>       NOTE: "React intentionally remounts your components in development"
    <MsalProvider instance={msalInstance}>
      <AppContextProvider>
        <ModelContextProvider>
          <ScheduleContextProvider>
            <App />
          </ScheduleContextProvider>
        </ModelContextProvider>
      </AppContextProvider>
    </MsalProvider>
    // </React.StrictMode>
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
