import { useContext } from "react";
import { AppContext } from "../context/app.context";
import { ModelContext } from "../context/model.context";
import { ScheduleContext } from "../context/schedule.context";
import { COLUMN_DAY_ARRAY } from "../utility/keys";
import UnassignedProjects from "../components/UnassignedProjects";
import JobTile from "./schedule/JobTile";
import ScheduleCell from "./schedule/ScheduleCell";
import ScheduleHeader from "./schedule/ScheduleHeader";

// NOTE: got help with filtering dates, using start & end values, for overlapping calculations from webpage:
//        https://stackoverflow.com/questions/17304426/javascript-date-range-between-date-range
//              An overlap test is slightly more complex. The following will return true if the two date ranges overlap, regardless of order.

export const ScheduleScreen = ({ children }) => {
  const {
    weekDatesArray,
    setOpenJobsDetailModal,
    dataFormatting_payrollFields,
  } = useContext(AppContext);
  const { filteredCrewsList } = useContext(ModelContext);
  const { scheduleArray, setDragObject, setDropObject, furnitureArray } =
    useContext(ScheduleContext);

  // - - - - - - - - - -

  const onProjectDetailClick = (id, event) => {
    event?.stopPropagation();
    console.log("ScheduleScreen onProjectDetailClick, id: ", id);
    // document.getElementById(SCREENS_PROJECTDETAIL_ID).style.display = "block";
    if (id !== "") {
      const projectDetail = scheduleArray.find(
        (document) => document.id === id
      );
      // console.log(
      //   "ScheduleScreen onProjectDetailClick, newProjectDetail: ",
      //   newProjectDetailArray
      // );
      setOpenJobsDetailModal({ show: true, jobsDetail: [projectDetail] });
    }
  };

  // - - - - - - - - - -

  const allowDropHere = (event) => {
    event.preventDefault();
  };

  // - - - - - - - - - -

  const onDragStarted = (id) => {
    // event.dataTransfer.setData("div", event.target.id);
    setDragObject(id);
    console.log("ScheduleScreen dragStarted id: ", id);
  };

  // - - - - - - - - - -

  const hasBeenDropped = (event, id) => {
    event.preventDefault();
    setDropObject(id);
    console.log("ScheduleScreen hasBeenDropped id: ", id);
  };

  // - - - - - - - - - -

  const renderDateHeaderRow = () => {
    return COLUMN_DAY_ARRAY.map((value, index) => {
      return (
        <ScheduleCell
          key={index}
          className="bg-header [&:nth-child(n+7)]:bg-header-lighter"
        >
          <div
            className="h-full border-b-2
            border-yellow text-sm flex flex-col justify-center items-center px-2"
          >
            <div className="flex justify-center w-full">
              <span className="flex-auto max-w-4.5"></span>
              <span className="font-medium text-nowrap">
                {value + " " + weekDatesArray[index]}
              </span>
            </div>
          </div>
        </ScheduleCell>
      );
    });
  };

  // - - - - - - - - - -

  const renderCrewCell = (document, candidateIndex) => {
    const softSurface_furnitureAvailable =
      furnitureArray[0]?.softSurface.available;
    const hardSurface_furnitureAvailable =
      furnitureArray[0]?.hardSurface.available;

    const softCrewFilteredCount = filteredCrewsList.filter(
      (crewDocument) => crewDocument?.Scheduling_Surface === "Soft Surface"
    ).length;

    return (
      <button
        key={document.__ID_Candidate}
        className="w-full text-black text-sm font-semibold flex-1 basis-32 "
      >
        {document.Scheduling_Surface.concat(
          " ",
          document.Scheduling_Surface === "Soft Surface"
            ? candidateIndex + 1 <= softSurface_furnitureAvailable
              ? "🪑"
              : ""
            : candidateIndex + 1 - softCrewFilteredCount <=
              hardSurface_furnitureAvailable
            ? "🪑"
            : ""
        )}
        <br></br>
        {candidateIndex}
      </button>
    );
  };

  // - - - - - - - - - -

  const renderCellContent = (columnIndex, candidateIndex, crew, value) => {
    const content =
      crew.Scheduling_Surface === "Soft Surface"
        ? renderSoftSurfaceCells(columnIndex, candidateIndex, crew)
        : renderHardSurfaceCells(columnIndex, candidateIndex, crew);
    return (
      <div
        className={`${
          content === "UNAVAILABLE"
            ? ""
            : crew.Scheduling_Surface === "Soft Surface"
            ? "bg-skin-surface-soft"
            : "bg-skin-surface-hard"
        } h-full flex flex-col`}
        onDrop={(e) => hasBeenDropped(e, "Unassigned_" + value)}
        onDragOver={(e) =>
          !isLocked(crew.payrollNo, columnIndex) &&
          !isLocked("column", columnIndex) &&
          content !== "UNAVAILABLE" &&
          allowDropHere(e)
        }
      >
        {content !== "UNAVAILABLE" ? content : ""}
      </div>
    );
  };

  // - - - - - - - - - -

  const renderSoftSurfaceCells = (
    columnIndex,
    candidateIndex,
    crewDocument
  ) => {
    const softSurface_furnitureAvailable =
      furnitureArray[columnIndex]?.softSurface.available;
    const softSurface_furnitureFilled =
      furnitureArray[columnIndex]?.softSurface.filled;

    const softCrewFilteredCount = filteredCrewsList.filter(
      (crewDocument) => crewDocument?.Scheduling_Surface === "Soft Surface"
    ).length;
    // array(objects) of unassigned but scheduled soft surface jobs for this date
    const cellScheduleList = scheduleArray
      .filter(
        (project) =>
          project?.surface === "Carpet" &&
          crewDocument?.Scheduling_Surface === "Soft Surface"
      )
      .filter(
        (project) =>
          ((new Date(project.start).getTime() >=
            new Date(weekDatesArray[columnIndex]).getTime() &&
            new Date(project.end).getTime() <=
              new Date(weekDatesArray[columnIndex]).getTime()) ||
            (new Date(weekDatesArray[columnIndex]).getTime() >=
              new Date(project.start).getTime() &&
              new Date(weekDatesArray[columnIndex]).getTime() <=
                new Date(project.end).getTime())) &&
          project?.installer === ""
      )
      .filter(
        (project) =>
          project?.encodedSmallTitle !== "" && project?.encodedLargeTitle !== ""
      );
    // array(objects) of assigned and scheduled soft & hard surface jobs for this date
    const assignedList = scheduleArray
      .filter(
        (project) =>
          ((new Date(project.start).getTime() >=
            new Date(weekDatesArray[columnIndex]).getTime() &&
            new Date(project.end).getTime() <=
              new Date(weekDatesArray[columnIndex]).getTime()) ||
            (new Date(weekDatesArray[columnIndex]).getTime() >=
              new Date(project.start).getTime() &&
              new Date(weekDatesArray[columnIndex]).getTime() <=
                new Date(project.end).getTime())) &&
          project?.installer !== ""
      )
      .filter(
        (project) =>
          project?.encodedSmallTitle !== "" && project?.encodedLargeTitle !== ""
      );
    // array(strings) of payrollNos for assigned and scheduled soft & hard surface jobs for this date
    const assignedPayrollList = assignedList.map((job) => {
      // DEBUG: console the list with payrollNo & invoiceNo
      // return { payrollNo: job.installer, invoiceNo: job.invoiceNo };
      return job.installer;
    });
    // array(strings) of payrollNos for assigned and scheduled soft surface crews for this date
    const softAssignedPayrollList = assignedPayrollList.filter(
      (assignedPayrollNo) =>
        filteredCrewsList.filter(
          (crew) =>
            dataFormatting_payrollFields(crew.payrollNo) ===
              assignedPayrollNo && crew.Scheduling_Surface === "Soft Surface"
        ).length > 0
    );
    // array(strings) of unique payrollNo's
    const softAssignedUniquePayrollList = [...new Set(softAssignedPayrollList)];
    //
    //
    // console.log(
    //   "renderSoftSurfaceCells assignedPayrollList: ",
    //   assignedPayrollList
    // );
    // console.log(
    //   "renderSoftSurfaceCells softAssignedPayrollList: ",
    //   softAssignedPayrollList
    // );
    // console.log(
    //   "renderSoftSurfaceCells softAssignedUniquePayrollList: ",
    //   softAssignedUniquePayrollList
    // );
    //

    return candidateIndex < softCrewFilteredCount - 1 &&
      cellScheduleList[candidateIndex]?.id ? ( // show job by cellIndex
      <JobTile
        key={cellScheduleList[candidateIndex]?.id}
        project={cellScheduleList[candidateIndex]}
        projectDetailClick={onProjectDetailClick}
        dragStarted={onDragStarted}
      ></JobTile>
    ) : // this formula reverses/pushes UNAVAILABLE cells to bottom, working up
    -1 * (candidateIndex - softCrewFilteredCount) <=
      softAssignedUniquePayrollList.length ? (
      "UNAVAILABLE"
    ) : candidateIndex + 1 <=
      softSurface_furnitureAvailable - softSurface_furnitureFilled ? (
      "🪑"
    ) : (
      ""
    );
  };

  // - - - - - - - - - -

  const renderHardSurfaceCells = (
    columnIndex,
    candidateIndex,
    crewDocument
  ) => {
    const hardSurface_furnitureAvailable =
      furnitureArray[columnIndex]?.hardSurface.available;
    const hardSurface_furnitureFilled =
      furnitureArray[columnIndex]?.hardSurface.filled;

    const softCrewFilteredCount = filteredCrewsList.filter(
      (crewDocument) => crewDocument?.Scheduling_Surface === "Soft Surface"
    ).length;
    const hardCrewFilteredCount = filteredCrewsList.filter(
      (crewDocument) => crewDocument?.Scheduling_Surface === "Hard Surface"
    ).length;
    // array(objects) of unassigned but scheduled hard surface jobs for this date
    const cellScheduleList = scheduleArray
      .filter(
        (project) =>
          project?.surface !== "Carpet" &&
          crewDocument?.Scheduling_Surface === "Hard Surface"
      )
      .filter(
        (project) =>
          ((new Date(project.start).getTime() >=
            new Date(weekDatesArray[columnIndex]).getTime() &&
            new Date(project.end).getTime() <=
              new Date(weekDatesArray[columnIndex]).getTime()) ||
            (new Date(weekDatesArray[columnIndex]).getTime() >=
              new Date(project.start).getTime() &&
              new Date(weekDatesArray[columnIndex]).getTime() <=
                new Date(project.end).getTime())) &&
          project?.installer === ""
      )
      .filter(
        (project) =>
          project?.encodedSmallTitle !== "" && project?.encodedLargeTitle !== ""
      );
    // array(objects) of assigned and scheduled soft & hard surface jobs for this date
    const assignedList = scheduleArray
      .filter(
        (project) =>
          ((new Date(project.start).getTime() >=
            new Date(weekDatesArray[columnIndex]).getTime() &&
            new Date(project.end).getTime() <=
              new Date(weekDatesArray[columnIndex]).getTime()) ||
            (new Date(weekDatesArray[columnIndex]).getTime() >=
              new Date(project.start).getTime() &&
              new Date(weekDatesArray[columnIndex]).getTime() <=
                new Date(project.end).getTime())) &&
          project?.installer !== ""
      )
      .filter(
        (project) =>
          project?.encodedSmallTitle !== "" && project?.encodedLargeTitle !== ""
      );
    // array(strings) of payrollNos for assigned and scheduled soft & hard surface jobs for this date
    const assignedPayrollList = assignedList.map((job) => {
      return job.installer;
    });
    // array(strings) of payrollNos for assigned and scheduled hard surface crews for this date
    const hardAssignedPayrollList = assignedPayrollList.filter(
      (assignedPayrollNo) =>
        filteredCrewsList.filter(
          (crew) =>
            dataFormatting_payrollFields(crew.payrollNo) ===
              assignedPayrollNo && crew.Scheduling_Surface === "Hard Surface"
        ).length > 0
    );
    // array(strings) of unique payrollNo's
    const hardAssignedUniquePayrollList = [...new Set(hardAssignedPayrollList)];

    return candidateIndex - softCrewFilteredCount < hardCrewFilteredCount - 1 &&
      cellScheduleList[candidateIndex - softCrewFilteredCount]?.id ? ( // show job by cellIndex
      <JobTile
        key={cellScheduleList[candidateIndex - softCrewFilteredCount]?.id}
        project={cellScheduleList[candidateIndex - softCrewFilteredCount]}
        projectDetailClick={onProjectDetailClick}
        dragStarted={onDragStarted}
      ></JobTile>
    ) : // this formula reverses/pushes UNAVAILABLE cells to bottom, working up
    -1 * (candidateIndex - hardCrewFilteredCount - softCrewFilteredCount) <=
      hardAssignedUniquePayrollList.length ? (
      "UNAVAILABLE"
    ) : candidateIndex + 1 - softCrewFilteredCount <=
      hardSurface_furnitureAvailable - hardSurface_furnitureFilled ? (
      "🪑"
    ) : (
      ""
    );
  };

  // - - - - - - - - - -

  const isLocked = (payrollNo, columnIndex) => {
    // ignoring COLUMN for now...
    if (payrollNo !== "column") {
      var payrollStr = "";
      if (payrollNo === "") {
        // unassigned cell
        payrollStr = "";
      } else {
        // assigned cell
        payrollStr = dataFormatting_payrollFields(payrollNo);
      }
      // filter for matching date & installer
      const currentValueArray = scheduleArray.filter(
        (document) =>
          payrollStr === document.installer &&
          weekDatesArray[columnIndex] === document.start
      );
      // if found it should have only one match
      const filteredArraySize = currentValueArray.length;
      // if (payrollStr === "") {
      //   console.log(
      //     payrollStr,
      //     " isLocked, column: ",
      //     columnIndex,
      //     ", weekDatesArray[columnIndex]: ",
      //     weekDatesArray[columnIndex],
      //     ", filteredArraySize: ",
      //     filteredArraySize,
      //     ", currentValueArray: ",
      //     currentValueArray
      //   );
      // }
      return filteredArraySize > 0 ? currentValueArray[0].locked : false;
    } else {
      return false;
    }
  };

  // - - - - - - - - - -

  return (
    <div className="schedule-page bg-skin-base">
      <div className="w-60 basis-60 shrink-0">
        <UnassignedProjects />
      </div>
      <div className="flex-auto flex flex-col overflow-hidden ml-6 px-4 pt-8">
        <div>
          <ScheduleHeader></ScheduleHeader>
        </div>
        <div className="overflow-auto h-full w-full mt-8 ">
          <div className="sticky top-0 z-10 text-white">
            <div className="flex min-h-[2.875rem]">
              <div
                className="flex justify-center items-center font-semibold bg-header border-b-2 border-yellow min-w-[8.6rem]
                          p-2 flex-1 overflow-hidden basis-32 first:sticky first:left-0"
              >
                <span>Crews</span>
              </div>
              {renderDateHeaderRow()}
            </div>
          </div>
          {filteredCrewsList.map((crew, candidateIndex) => (
            <div
              className="flex mt-2 [&:nth-child(2)]:mt-0 min-h-12"
              key={candidateIndex}
            >
              <ScheduleCell>
                <div
                  className={`${
                    crew.Scheduling_Surface === "Soft Surface"
                      ? "bg-crew-surface-soft"
                      : "bg-crew-surface-hard"
                  } p-2 h-full`}
                >
                  {renderCrewCell(crew, candidateIndex)}
                </div>
              </ScheduleCell>
              {COLUMN_DAY_ARRAY.map((value, columnIndex) => (
                <ScheduleCell key={columnIndex}>
                  {renderCellContent(columnIndex, candidateIndex, crew, value)}
                </ScheduleCell>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
