const Button = ({
  children,
  disabled,
  onClick,
  color,
  className,
  refInner,
}) => {
  let colorBg = "bg-skin-button-accent";
  let colorText = "text-black";
  switch (color) {
    case "inactive":
      colorBg = "bg-yellow-30";

      break;
    case "light":
      colorBg = "border-2 border-yellow";
      colorText = "text-skin-primary";
      break;
    case "clear":
      colorBg = "";
      break;
    default:
      break;
  }
  return (
    <button
      className={`${
        disabled ? "cursor-default opacity-70" : ""
      } ${colorBg} ${colorText} ${className} text-sm  font-medium px-4 hover:opacity-75`}
      onClick={onClick}
      disabled={disabled}
      ref={refInner}
    >
      {children}
    </button>
  );
};

export default Button;
