const WeekPicker = ({ disabled, weekBehind, weekAhead, value }) => {
  return (
    <div className="flex">
      <button
        className="hover:opacity-75"
        onClick={weekBehind}
        disabled={disabled}
      >
        <span className="arrow arrow-right"></span>
      </button>
      <div className="h-full min-w-[90px] flex items-center justify-center bg-yellow-30 text-black px-2">
        <span>{value}</span>
      </div>
      <button
        className="hover:opacity-75"
        onClick={weekAhead}
        disabled={disabled}
      >
        <span className="arrow"></span>
      </button>
    </div>
  );
};

export default WeekPicker;
