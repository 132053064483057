const TableHeader = ({ sortingState, sortChange, children, field }) => {
  return (
    <div
      className="flex items-center gap-1 cursor-pointer h-6 select-none"
      onClick={() => sortChange(field)}
    >
      {children}
      {sortingState?.field === field ? (
        sortingState?.direction === "asc" ? (
          <span className="material-symbols-outlined">expand_more</span>
        ) : (
          <span className="material-symbols-outlined">expand_less</span>
        )
      ) : (
        <span className="w-6"></span>
      )}
    </div>
  );
};

export default TableHeader;
