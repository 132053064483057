import { useContext, useEffect, useState } from "react";
import Button from "../../components/Button";
import Checkbox from "../../components/Checkbox";
import Modal from "../../components/Modal";
import JobTile from "./JobTile";
import ScheduleCell from "./ScheduleCell";
import LockOpen from "../../components/LockOpen";
import { AppContext } from "../../context/app.context";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

function JobSettingsModal({ setOpenJobSettingsModal }) {
  const {
    dataFormatting_encodedLargeTitleNew,
    dataFormatting_encodedSmallTitleNew,
    jobTitleConfig,
    setJobTitleConfig,
  } = useContext(AppContext);

  const [mockJob, setMockJob] = useState({
    _id: 0,
    storeNo: "1873",
    classification: "Job",
    invoiceNo: 2035065,
    projectNo: "805228064",
    qty: 135,
    statusCode: 15,
    lastName: "Betmallo",
    flags: "📦🪑📅🏃🏽‍♂️✈️🛑",
    encodedLargeTitle: "",
    encodedSmallTitle: "",
    surface: "Wood",
    encodedArrival: "01-03",
  });
  // cloning saved config
  const [tempJobTitleConfig, setTempJobTitleConfig] = useState(
    jobTitleConfig.map((x) => ({ ...x }))
  );

  // ----------

  const onChangeConfig = (field) => {
    let _arr = [...tempJobTitleConfig];
    console.log(
      "JobSettingsModal onChangeConfig tempJobTitleConfig:",
      tempJobTitleConfig
    );
    const fieldConfig = _arr.find((el) => el.field === field);
    if (fieldConfig) {
      fieldConfig.selected = !fieldConfig.selected;
      setTempJobTitleConfig(_arr);
    }
  };

  // ----------

  const onDragEnded = (param) => {
    const { source, destination } = param;
    if (destination) {
      let _arr = [...tempJobTitleConfig];
      //extracting the source item from the list
      const _item = _arr.splice(source.index, 1)[0];
      //inserting it at the destination index.
      _arr.splice(destination.index, 0, _item);
      setTempJobTitleConfig(_arr);
    }
  };

  // ----------

  const onSaveJobSettings = () => {
    setJobTitleConfig(tempJobTitleConfig);
    // localStorage.setItem("jobTitleConfig", JSON.stringify(tempJobTitleConfig));
    setOpenJobSettingsModal({ show: false });
  };

  // - - - - - - - - - - - - - - - - -  EFFECT HOOKS  - - - - - - - - - - - - - - - - - -

  useEffect(() => {
    const newEncodedLargeTitle = dataFormatting_encodedLargeTitleNew(
      mockJob,
      tempJobTitleConfig
    );

    const newEncodedSmallTitle = dataFormatting_encodedSmallTitleNew(
      mockJob,
      tempJobTitleConfig
    );
    setMockJob({
      ...mockJob,
      encodedLargeTitle: newEncodedLargeTitle,
      encodedSmallTitle: newEncodedSmallTitle,
    });
  }, [
    tempJobTitleConfig,
    dataFormatting_encodedLargeTitleNew,
    dataFormatting_encodedSmallTitleNew,
  ]);

  // - - - - - - - - - -

  return (
    <Modal onClose={() => setOpenJobSettingsModal({ show: false })}>
      <Modal.Header>Job Settings</Modal.Header>
      <Modal.Content>
        <div className="flex flex-col gap-4">
          <div>Select fields and their order to display in the jobs list:</div>
          <div className="text-lg font-bold">Job Info</div>
          <div>
            <ul>
              {tempJobTitleConfig
                .slice(0, 2)
                .map(({ name, field, selected }, index) => (
                  <li className="flex gap-2 bg-skin-base pl-8" key={index}>
                    <Checkbox
                      label={name}
                      onChange={() => onChangeConfig(field)}
                      value={selected}
                    />
                  </li>
                ))}
            </ul>
            <DragDropContext onDragEnd={onDragEnded}>
              <Droppable droppableId="job-info-wrapper">
                {(provided, snapshot) => (
                  <ul ref={provided.innerRef} {...provided.droppableProps}>
                    {tempJobTitleConfig
                      .slice(2)
                      .map(({ name, field, selected }, index) => (
                        <Draggable
                          draggableId={`job-info-${field}`}
                          index={index + 2}
                          key={field}
                        >
                          {(dragProvided, dragSnapshot) => (
                            <li
                              className="flex gap-2 bg-skin-base"
                              ref={dragProvided.innerRef}
                              {...dragProvided.draggableProps}
                            >
                              <span
                                className="material-symbols-outlined cursor-move"
                                {...dragProvided.dragHandleProps}
                              >
                                drag_handle
                              </span>
                              <Checkbox
                                label={name}
                                onChange={() => onChangeConfig(field)}
                                value={selected}
                              />
                            </li>
                          )}
                        </Draggable>
                      ))}
                    {provided.placeholder}
                  </ul>
                )}
              </Droppable>
            </DragDropContext>
          </div>
          <div className="text-lg font-bold">Preview</div>
          <div className="flex gap-4 pb-4">
            <div className="self-start p-4 bg-skin-unassigned w-60">
              <JobTile
                project={mockJob}
                className="bg-skin-primary mb-2"
                largeView={true}
              ></JobTile>
              <JobTile project={mockJob} className="bg-skin-primary"></JobTile>
            </div>
            <div className="w-44">
              <ScheduleCell className="bg-header">
                <div className="h-full border-b-2 border-yellow text-sm flex flex-col justify-center items-center px-2 text-white">
                  <div className="flex justify-between w-full">
                    <span className="flex-auto max-w-4.5"></span>
                    <span className="font-medium text-nowrap">
                      {new Date().toLocaleDateString("en-us", {
                        weekday: "short",
                        day: "numeric",
                        month: "numeric",
                        year: "numeric",
                      })}
                    </span>
                    <button className="fill-primary w-4.5">
                      <LockOpen></LockOpen>
                    </button>
                  </div>
                  <div className="flex justify-between w-full">
                    <span>
                      S:&nbsp;
                      <span className="font-bold">2</span>
                      &nbsp;/&nbsp;
                      <span className="font-bold">4</span>
                    </span>
                    <span>
                      H:&nbsp;
                      <span className="font-bold">3</span>
                      &nbsp;/&nbsp;
                      <span className="font-bold">{5}</span>
                    </span>
                  </div>
                </div>
              </ScheduleCell>
              <div>
                <ScheduleCell>
                  <div className={`bg-skin-surface-hard  h-full flex flex-col`}>
                    <JobTile project={mockJob}></JobTile>
                    <div className="flex-1 flex items-end justify-end p-2">
                      <button className="fill-skin-base w-6 h-6">
                        <LockOpen></LockOpen>
                      </button>
                    </div>
                  </div>
                </ScheduleCell>
              </div>
            </div>
          </div>
        </div>
      </Modal.Content>
      <Modal.Footer>
        <div className="h-8 flex gap-4">
          <Button
            className="uppercase w-31"
            onClick={() => onSaveJobSettings()}
          >
            Save
          </Button>
          <Button
            color="light"
            className="uppercase w-31"
            onClick={() => setOpenJobSettingsModal({ show: false })}
          >
            Cancel
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default JobSettingsModal;
