import React, { useRef, useState } from "react";
import { useEffect } from "react";
import listenForOutsideClicks from "../utility/listen-for-outside-clicks";

function Dropdown({ trigger, menu, menuClasses }) {
  const [isOpen, setIsOpen] = useState(false);
  const [listening, setListening] = useState(false);
  const menuRef = useRef(null);
  const toggle = () => setIsOpen(!isOpen);

  // - - - - - - - - - -

  useEffect(() => {
    listenForOutsideClicks(listening, setListening, menuRef, setIsOpen);
  }, [listening]);

  // - - - - - - - - - -

  return (
    <div ref={menuRef} className="relative">
      <div
        onClick={toggle}
        data-dropdown-toggle="dropdown"
        className="cursor-pointer"
      >
        {trigger}
      </div>
      <div
        onClick={toggle}
        className={`${
          isOpen ? "" : "hidden"
        } z-30 absolute bg-skin-base py-2 ${menuClasses}`}
      >
        {menu}
      </div>
    </div>
  );
}
export default Dropdown;
