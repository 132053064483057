import React from "react";

const getChildrenOnDisplayName = (children, displayName) =>
  React.Children.map(children, (child) =>
    child.type.displayName === displayName ? child : null
  );

const Modal = ({ children, onClose, disableCloseOnClickOutSide }) => {
  const header = getChildrenOnDisplayName(children, "header");
  const content = getChildrenOnDisplayName(children, "content");
  const footer = getChildrenOnDisplayName(children, "footer");

  // - - - - - - - - - -

  return (
    // Main modal
    <section
      className="w-full top-0 z-50 bg-black bg-opacity-70 flex items-center justify-center  
            fixed inset-x-0  h-screen overflow-y-auto overflow-x-hidden max-h-screen"
      id="jobs-detail-modal"
      tabIndex="-1"
      onClick={() => !disableCloseOnClickOutSide && onClose()}
    >
      {/* Modal content */}
      <div
        className="bg-skin-base text-skin-primary fill-skin-base min-h-36 min-w-80 flex flex-col max-h-[90%]"
        onClick={(e) => e.stopPropagation()}
      >
        {/* Modal header */}
        <div className="bg-skin-primary border-b-2 border-yellow  px-4 py-3 text-xl font-medium flex gap-4">
          <div className="flex-1">{header}</div>
          <button onClick={() => onClose()}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <mask
                id="mask0_441_3316"
                style={{ maskType: "alpha" }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
              >
                <rect width="24" height="24" />
              </mask>
              <g mask="url(#mask0_441_3316)">
                <path d="M6.4 19L5 17.6L10.6 12L5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19Z" />
              </g>
            </svg>
          </button>
        </div>
        {/* Modal body */}
        <div className="p-4 flex-1 basis-full overflow-y-auto overflow-x-hidden text-sm">
          {content}
        </div>
        {/* Modal footer */}
        <div className="px-4 pb-4 flex justify-center gap-4">{footer}</div>
      </div>
    </section>
  );
};

const Header = ({ children }) => <>{children}</>;

Header.displayName = "header";
Modal.Header = Header;

const Content = ({ children }) => <>{children}</>;

Content.displayName = "content";
Modal.Content = Content;

const Footer = ({ children }) => <>{children}</>;

Footer.displayName = "footer";
Modal.Footer = Footer;

export default Modal;
