import React, { forwardRef, useState, useContext } from "react";
import Modal from "../../components/Modal";
import Button from "../../components/Button";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ModelContext } from "../../context/model.context";

function SuppressModal({ currentJob, suppress, setOpenSuppressModal }) {
  const { setSuppressionById } = useContext(ModelContext);
  const [activePeriod, setActivePeriod] = useState(1);
  const todayDate = new Date();
  // initial date is tomorrow
  todayDate.setDate(todayDate.getDate() + 1);
  const [suppressDate, setSuppressDate] = useState(todayDate);
  const [notes, setNotes] = useState("");

  console.log("SuppressModal currentJob: ", currentJob);

  // - - - - - - - - - -

  const onSave = () => {
    // reminder: this button is only visible/usable when statusCode === 3
    const suppressedDate = new Date(suppressDate).toLocaleDateString("en-us");
    const newNotes = notes?.trim();
    if (newNotes) {
      // Save Suppress
      console.log(
        "SuppressModal -> onSave -> date:",
        suppressDate?.toLocaleDateString("en-us"),
        " custom:",
        newNotes
      );

      // update Mongo (changeStream will push status change back to this client)
      const newStatusCode = 3.1;
      setSuppressionById(
        currentJob.mongoId,
        suppressedDate,
        newNotes,
        newStatusCode
      );

      // Close modal window
      setOpenSuppressModal({ show: false });
    } else {
      console.log(
        "SuppressModal onSave error (alert to user 'Lowe's IMS Note is required'): "
      );
      alert("Lowe's IMS Note is required");
    }
  };

  // - - - - - - - - - -

  const onSetActivePeriod = (period) => {
    setActivePeriod(period);
    if (period >= 1) {
      const todayDate = new Date();
      todayDate.setDate(todayDate.getDate() + period);
      setSuppressDate(todayDate);
    }
  };

  // - - - - - - - - - -

  const onSuppressDateChange = (date) => {
    setSuppressDate(date);
    setActivePeriod(-1);
  };

  // - - - - - - - - - -

  const EstCompleteInput = forwardRef(({ value, onClick, className }, ref) => (
    <Button
      color="clear"
      className={`${className}`}
      refInner={ref}
      onClick={onClick}
    >
      <div className="flex items-center">
        <span>{value}</span>
        <span className="ml-1 arrow arrow-down arrow-small"></span>
      </div>
    </Button>
  ));

  // - - - - - - - - - -

  return (
    <Modal onClose={() => setOpenSuppressModal({ show: false })}>
      <Modal.Header>
        Suppress Job# <span className="font-bold">{suppress?.projectNo}</span>
      </Modal.Header>
      <Modal.Content>
        {suppress ? (
          <div className="flex flex-col gap-4">
            <div className="flex flex-wrap w-56">
              <div className="flex-1 basis-2/4">Invoice#:</div>
              <div className="flex-1 basis-2/4 font-bold">
                {suppress.invoiceNo}
              </div>
            </div>
            <div className="flex flex-wrap w-56">
              <div className="flex-1 basis-2/4">Classification:</div>
              <div className="flex-1 basis-2/4 font-bold">
                {suppress.classification}
              </div>
            </div>
            <div className="text-lg font-bold">Suppress for ...</div>
            <div className="flex h-6">
              <Button
                color="clear"
                className={`w-28 ${
                  activePeriod === 1
                    ? "bg-skin-button-accent"
                    : "bg-crew-surface-hard"
                } `}
                onClick={() => onSetActivePeriod(1)}
              >
                1 Day
              </Button>
              <Button
                color="clear"
                className={`w-28 ${
                  activePeriod === 3
                    ? "bg-skin-button-accent"
                    : "bg-crew-surface-hard"
                } border-l border-l-black/[.20]`}
                onClick={() => onSetActivePeriod(3)}
              >
                3 Day
              </Button>
              <Button
                color="clear"
                className={`w-28 ${
                  activePeriod === 5
                    ? "bg-skin-button-accent"
                    : "bg-crew-surface-hard"
                } border-l border-l-black/[.20]`}
                onClick={() => onSetActivePeriod(5)}
              >
                5 Day
              </Button>
              <DatePicker
                selected={suppressDate}
                onChange={(date) => onSuppressDateChange(date)}
                className=""
                customInput={
                  <EstCompleteInput
                    className={`w-28 h-6 ${
                      activePeriod === -1
                        ? "bg-skin-button-accent"
                        : "bg-crew-surface-hard"
                    } border-l border-l-black/[.20]`}
                  />
                }
                dateFormat="M/d/YYYY"
              />
            </div>
            <div className="text-lg font-bold">Lowe's IMS Note</div>
            <textarea
              className="h-32 p-2 text-black border border-black/[.20]"
              placeholder="Custom"
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
            ></textarea>
          </div>
        ) : (
          "Suppress not found"
        )}
      </Modal.Content>
      <Modal.Footer>
        <div className="h-8 flex gap-4">
          <Button className="uppercase w-31" onClick={() => onSave()}>
            Save
          </Button>
          <Button
            color="light"
            className="uppercase w-31"
            onClick={() => setOpenSuppressModal({ show: false })}
          >
            Cancel
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default SuppressModal;
