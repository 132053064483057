const ScheduleCell = ({ children, className }) => {
  return (
    <div
      className={`${className} min-w-[8.6rem] flex-1 overflow-hidden basis-32 ml-4 first:ml-0 first:sticky first:left-0`}
    >
      {children}
    </div>
  );
};

export default ScheduleCell;
