import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../context/app.context";
import { ScheduleContext } from "../context/schedule.context";
import Checkbox from "./Checkbox";
import { FILTERS_SURFACE_ARRAY } from "../utility/keys";
import Input from "./Input";
import search from "./../assets/icons/search.svg";
import JobTile from "../screens/schedule/JobTile";

const UnassignedProjects = () => {
  const [hideFilter, setHideFilter] = useState(true);
  const [hideSuppressed, setHideSuppressed] = useState(true);
  const [suppressedArray, setSuppressedArray] = useState([]);

  const [query, setQuery] = useState("");
  const {
    currentFilters,
    onSurfaceFiltersChanged,
    setOpenJobsDetailModal,
    largeView,
    setLargeView,
  } = useContext(AppContext);
  const { scheduleArray, setDragObject, setDropObject, schedulePreparing } =
    useContext(ScheduleContext);

  // - - - - - - - - - -

  const onProjectDetailClick = (project) => {
    setOpenJobsDetailModal({ show: true, jobsDetail: [project] });
    console.log("UnassignedProjects onProjectDetailClick, project: ", project);
  };

  // - - - - - - - - - -

  const allowDropHere = (event) => {
    event.preventDefault();
    console.log("UnassignedProjects allowDropHere...");
  };

  // - - - - - - - - - -

  const onDragStarted = (id) => {
    // event.dataTransfer.setData("div", event.target.id);
    setDragObject(id);
    console.log("UnassignedProjects dragStarted id: ", id);
  };

  // - - - - - - - - - -

  const hasBeenDropped = (event, id) => {
    event.preventDefault();
    setDropObject(id);
    console.log("UnassignedProjects hasBeenDropped id: ", id);
  };

  // - - - - - - - - - -

  const onSearch = () => {
    console.log("UnassignedProjects onSearch:", query);
  };

  // - - - - - - - - - -

  const renderStandardCells = () => {
    return scheduleArray
      .filter((project) => {
        // filter query by: invoiceNo, projectNo, phoneNo, firstName, lastName
        const queryLength = query.length;
        const querySlice = query.toLowerCase().slice(0, queryLength);
        const invoiceNoSlice = project.invoiceNo
          ?.toString()
          .slice(0, queryLength);
        const projectNoSlice = project.projectNo?.slice(0, queryLength);
        const phoneNoSlice = project.cellPhone?.slice(0, queryLength);
        const projectFirstnameSlice = project.firstName
          ?.toLowerCase()
          .slice(0, queryLength);
        const projectLastnameSlice = project.lastName
          ?.toLowerCase()
          .slice(0, queryLength);
        return (
          project.start === "" &&
          parseInt(project.statusCode) < 4 &&
          parseFloat(project.statusCode) !== 3.1 &&
          (querySlice === projectLastnameSlice ||
            querySlice === projectFirstnameSlice ||
            querySlice === invoiceNoSlice ||
            querySlice === projectNoSlice ||
            querySlice === phoneNoSlice)
        );
      })
      .map((project, index) => (
        <JobTile
          key={index}
          projectDetailClick={() => onProjectDetailClick(project)}
          //dragStarted={() => setDragObject(project.id)}
          dragStarted={onDragStarted}
          project={project}
          className="bg-skin-primary mb-2 last:mb-0"
          largeView={largeView}
        ></JobTile>
      ));
  };

  // - - - - - - - - - -

  const renderQueriedCells = () => {
    return scheduleArray
      .filter(
        (project) =>
          project.start === "" &&
          parseInt(project.statusCode) < 4 &&
          parseFloat(project.statusCode) !== 3.1
      )
      .map((project, index) => (
        <JobTile
          key={index}
          projectDetailClick={() => onProjectDetailClick(project)}
          //dragStarted={() => setDragObject(project.id)}
          dragStarted={onDragStarted}
          project={project}
          className="bg-skin-primary mb-2 last:mb-0"
          largeView={largeView}
        ></JobTile>
      ));
  };

  // - - - - - - - - - - - - - - - - -  EFFECT HOOKS  - - - - - - - - - - - - - - - - - -
  //
  // largeView
  //

  useEffect(() => {
    localStorage.setItem("largeView", JSON.stringify(largeView));
  }, [largeView]);

  // - - - - - - - - - -

  useEffect(() => {
    setSuppressedArray(
      query !== ""
        ? scheduleArray.filter((project) => {
            // filter query by: invoiceNo, projectNo, phoneNo, firstName, lastName
            const queryLength = query.length;
            const querySlice = query.toLowerCase().slice(0, queryLength);
            const invoiceNoSlice = project.invoiceNo
              ?.toString()
              .slice(0, queryLength);
            const projectNoSlice = project.projectNo?.slice(0, queryLength);
            const phoneNoSlice = project.cellPhone?.slice(0, queryLength);
            const projectFirstnameSlice = project.firstName
              ?.toLowerCase()
              .slice(0, queryLength);
            const projectLastnameSlice = project.lastName
              ?.toLowerCase()
              .slice(0, queryLength);
            return (
              project.start === "" &&
              parseFloat(project.statusCode) === 3.1 &&
              (querySlice === projectLastnameSlice ||
                querySlice === projectFirstnameSlice ||
                querySlice === invoiceNoSlice ||
                querySlice === projectNoSlice ||
                querySlice === phoneNoSlice)
            );
          })
        : scheduleArray.filter(
            (job) => job.start === "" && job.statusCode === 3.1
          )
    );
  }, [scheduleArray, query]);

  // - - - - - - - - - -

  return (
    <section className="flex flex-col bg-skin-unassigned px-4 h-full">
      <div className="h-[4.5rem] flex items-center">
        <div className="flex-1 text-lg font-bold">Unassigned</div>
        <button onClick={() => setHideFilter(!hideFilter)} className="ml-2">
          {hideFilter ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              className="fill-skin-base"
            >
              <g clipPath="url(#clip0_1_539)">
                <path d="M6.99999 6H17L11.99 12.3L6.99999 6ZM4.24999 5.61C6.26999 8.2 9.99999 13 9.99999 13V19C9.99999 19.55 10.45 20 11 20H13C13.55 20 14 19.55 14 19V13C14 13 17.72 8.2 19.74 5.61C20.25 4.95 19.78 4 18.95 4H5.03999C4.20999 4 3.73999 4.95 4.24999 5.61Z" />
              </g>
              <defs>
                <clipPath id="clip0_1_539">
                  <rect width="24" height="24" />
                </clipPath>
              </defs>
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              className="fill-skin-base"
            >
              <mask
                id="mask0_21_2701"
                style={{ maskType: "alpha" }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
              >
                <rect width="24" height="24" />
              </mask>
              <g mask="url(#mask0_21_2701)">
                <path d="M14.7999 11.975L13.3749 10.55L16.9499 6H8.8249L6.8249 4H18.9999C19.4166 4 19.7166 4.18334 19.8999 4.55C20.0832 4.91667 20.0499 5.26667 19.7999 5.6L14.7999 11.975ZM19.7749 22.6L13.9999 16.825V19C13.9999 19.2833 13.9041 19.5208 13.7124 19.7125C13.5207 19.9042 13.2832 20 12.9999 20H10.9999C10.7166 20 10.4791 19.9042 10.2874 19.7125C10.0957 19.5208 9.9999 19.2833 9.9999 19V12.825L1.3999 4.225L2.7999 2.8L21.1999 21.2L19.7749 22.6Z" />
              </g>
            </svg>
          )}
        </button>
        <button onClick={() => setLargeView(!largeView)} className="ml-2">
          {largeView ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              className="fill-skin-base"
            >
              <path d="M5.24999 1.96875V4.78125C5.24999 5.24725 4.87223 5.625 4.40624 5.625H0.84375C0.377754 5.625 0 5.24725 0 4.78125V1.96875C0 1.50275 0.377754 1.125 0.84375 1.125H4.40624C4.87223 1.125 5.24999 1.50275 5.24999 1.96875ZM11.625 10.4062V7.59375C11.625 7.12775 11.2473 6.75 10.7813 6.75H7.21874C6.75274 6.75 6.37499 7.12775 6.37499 7.59375V10.4062C6.37499 10.8722 6.75274 11.25 7.21874 11.25H10.7812C11.2473 11.25 11.625 10.8722 11.625 10.4062ZM12.75 1.96875V4.78125C12.75 5.24725 13.1278 5.625 13.5938 5.625H17.1562C17.6222 5.625 18 5.24725 18 4.78125V1.96875C18 1.50275 17.6222 1.125 17.1562 1.125H13.5938C13.1278 1.125 12.75 1.50275 12.75 1.96875ZM11.625 4.78125V1.96875C11.625 1.50275 11.2473 1.125 10.7813 1.125H7.21874C6.75274 1.125 6.37499 1.50275 6.37499 1.96875V4.78125C6.37499 5.24725 6.75274 5.625 7.21874 5.625H10.7812C11.2473 5.625 11.625 5.24725 11.625 4.78125ZM4.40624 6.75H0.84375C0.377754 6.75 0 7.12775 0 7.59375V10.4062C0 10.8722 0.377754 11.25 0.84375 11.25H4.40624C4.87223 11.25 5.24999 10.8722 5.24999 10.4062V7.59375C5.24999 7.12775 4.87223 6.75 4.40624 6.75ZM0 13.2188V16.0312C0 16.4972 0.377754 16.875 0.84375 16.875H4.40624C4.87223 16.875 5.24999 16.4972 5.24999 16.0312V13.2188C5.24999 12.7528 4.87223 12.375 4.40624 12.375H0.84375C0.377754 12.375 0 12.7528 0 13.2188ZM13.5938 11.25H17.1562C17.6222 11.25 18 10.8722 18 10.4062V7.59375C18 7.12775 17.6222 6.75 17.1562 6.75H13.5938C13.1278 6.75 12.75 7.12775 12.75 7.59375V10.4062C12.75 10.8722 13.1278 11.25 13.5938 11.25ZM13.5938 16.875H17.1562C17.6222 16.875 18 16.4972 18 16.0312V13.2188C18 12.7528 17.6222 12.375 17.1562 12.375H13.5938C13.1278 12.375 12.75 12.7528 12.75 13.2188V16.0312C12.75 16.4972 13.1278 16.875 13.5938 16.875ZM6.37499 13.2188V16.0312C6.37499 16.4972 6.75274 16.875 7.21874 16.875H10.7812C11.2472 16.875 11.625 16.4972 11.625 16.0312V13.2188C11.625 12.7528 11.2472 12.375 10.7812 12.375H7.21874C6.75274 12.375 6.37499 12.7528 6.37499 13.2188Z" />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="16"
              viewBox="0 0 18 16"
              className="fill-skin-base"
            >
              <path d="M10.4062 0.125H17.1562C17.6222 0.125 18 0.502754 18 0.96875V6.59375C18 7.05975 17.6222 7.4375 17.1562 7.4375H10.4062C9.94025 7.4375 9.5625 7.05975 9.5625 6.59375V0.96875C9.5625 0.502754 9.94025 0.125 10.4062 0.125ZM7.59375 0.125H0.84375C0.377754 0.125 0 0.502754 0 0.96875V6.59375C0 7.05975 0.377754 7.4375 0.84375 7.4375H7.59375C8.05975 7.4375 8.4375 7.05975 8.4375 6.59375V0.96875C8.4375 0.502754 8.05975 0.125 7.59375 0.125ZM0 9.40625V15.0313C0 15.4972 0.377754 15.875 0.84375 15.875H7.59375C8.05975 15.875 8.4375 15.4972 8.4375 15.0313V9.40625C8.4375 8.94025 8.05975 8.5625 7.59375 8.5625H0.84375C0.377754 8.5625 0 8.94025 0 9.40625ZM10.4062 15.875H17.1562C17.6222 15.875 18 15.4972 18 15.0313V9.40625C18 8.94025 17.6222 8.5625 17.1562 8.5625H10.4062C9.94025 8.5625 9.5625 8.94025 9.5625 9.40625V15.0313C9.5625 15.4972 9.94025 15.875 10.4062 15.875Z" />
            </svg>
          )}
        </button>
      </div>
      <div className="my-4 h-8">
        <Input
          placeholder="Quick Search"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          onEnter={() => onSearch()}
          className="bg-yellow-30 text-black"
        >
          <Input.Suffix>
            <button className="w-6" onClick={() => onSearch()}>
              <img src={search} alt="search" />
            </button>
          </Input.Suffix>
        </Input>
      </div>

      <div className={`${hideFilter ? "hidden" : ""} pb-8`}>
        {FILTERS_SURFACE_ARRAY.map(({ name }, index) => (
          <div key={index} className="mb-2 last:mb-0 flex">
            <div
              className={`bg-surface-${name?.split(" ").join("-")} badge mr-2 `}
            ></div>
            <Checkbox
              label={name}
              disabled={schedulePreparing}
              value={currentFilters.surfaceFilters[index]}
              onChange={() => onSurfaceFiltersChanged(index)}
            />
          </div>
        ))}
      </div>
      <div
        className="flex-1 overflow-auto"
        onDragOver={(e) => allowDropHere(e)}
        onDrop={(e) => hasBeenDropped(e, "Unassigned_RTS")}
      >
        {query !== "" ? renderStandardCells() : renderQueriedCells()}
      </div>
      <button
        className="py-2 flex gap-2 items-center"
        onClick={() => setHideSuppressed(!hideSuppressed)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          className="fill-skin-primary"
        >
          <g clipPath="url(#clip0_907_957)">
            <path d="M7.99992 1.33337C4.31992 1.33337 1.33325 4.32004 1.33325 8.00004C1.33325 11.68 4.31992 14.6667 7.99992 14.6667C11.6799 14.6667 14.6666 11.68 14.6666 8.00004C14.6666 4.32004 11.6799 1.33337 7.99992 1.33337ZM11.3333 8.66671H4.66659V7.33337H11.3333V8.66671Z" />
          </g>
          <defs>
            <clipPath id="clip0_907_957">
              <rect width="16" height="16" />
            </clipPath>
          </defs>
        </svg>
        <span className="font-bold flex-1 text-left">
          Suppressed ({suppressedArray?.length})
        </span>
        {hideSuppressed ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            className="fill-skin-primary"
          >
            <g clipPath="url(#clip0_909_991)">
              <path d="M7.41 8.58997L12 13.17L16.59 8.58997L18 9.99997L12 16L6 9.99997L7.41 8.58997Z" />
            </g>
            <defs>
              <clipPath id="clip0_909_991">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            className="fill-skin-primary"
          >
            <g clipPath="url(#clip0_907_963)">
              <path d="M7.41 15.41L12 10.83L16.59 15.41L18 14L12 8.00003L6 14L7.41 15.41Z" />
            </g>
            <defs>
              <clipPath id="clip0_907_963">
                <rect
                  width="24"
                  height="24"
                  fill="white"
                  transform="matrix(1 0 0 -1 0 24)"
                />
              </clipPath>
            </defs>
          </svg>
        )}
      </button>
      <div
        className={`${
          hideSuppressed ? "h-0" : "h-1/3"
        } overflow-auto transition-['height'] duration-200`}
      >
        {suppressedArray.map((project, index) => (
          <JobTile
            key={index}
            projectDetailClick={() => onProjectDetailClick(project)}
            dragStarted={() => setDragObject(project.id)}
            project={project}
            className="bg-skin-primary mb-2 last:mb-0 opacity-50"
            largeView={largeView}
          ></JobTile>
        ))}
      </div>
    </section>
  );
};

export default UnassignedProjects;
